.p-autocomplete {
    box-shadow: $box-shadow;
    .p-inputtext {
    }
    .p-autocomplete-loader {
        position: absolute;
        right: 5px;
    }
}
.p-autocomplete-panel {
    -webkit-box-shadow: $dropdown-box-shadow;
    box-shadow: $dropdown-box-shadow;
    background-color: $white;
    .p-autocomplete-items {
        // padding: 0 0 0 5px;

        -webkit-transition: $transition-base;
        transition: $transition-base;
        margin: 0;
        .p-autocomplete-item {
            padding-right: $form-select-padding-x;
            padding-left: $form-select-padding-x;
            // padding-right: $form-select-padding-x;
            // padding-left: $form-select-padding-x;
            // -webkit-transition: $transition-base;
            // transition: $transition-base;
            // margin: 0;
        }
        .p-autocomplete-item:hover {
            background-color: $black;
            color: $white;
        }
        .p-autocomplete-empty-message {
            padding-right: $form-select-padding-x;
            padding-left: $form-select-padding-x;
        }
    }
}
// .p-autocomplete .p-autocomplete-loader {
//     right: 0.75rem;
// }
// .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
//     right: 3.75rem;
// }
// .p-autocomplete .p-autocomplete-multiple-container {
//     padding: 0.375rem 0.75rem;
// }
// .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
//     border-color: #6366f1;
// }
// .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
//     outline: 0 none;
//     outline-offset: 0;
//     box-shadow: 0 0 0 0.2rem #c7d2fe;
//     border-color: #6366f1;
// }
// .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
//     padding: 0.375rem 0;
// }
// .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
//         "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//     font-size: 1rem;
//     color: #495057;
//     padding: 0;
//     margin: 0;
// }
// .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
//     padding: 0.375rem 0.75rem;
//     margin-right: 0.5rem;
//     background: #eef2ff;
//     color: #4338ca;
//     border-radius: 6px;
// }
// .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
//     margin-left: 0.5rem;
// }
// .p-autocomplete.p-invalid.p-component > .p-inputtext {
//     border-color: #e24c4c;
// }

// .p-autocomplete-panel {
//     background: #ffffff;
//     color: #495057;
//     border: 0 none;
//     border-radius: 6px;
//     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-autocomplete-panel .p-autocomplete-items {
//     padding: 0.75rem 0;
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
//     margin: 0;
//     padding: 0.75rem 1.25rem;
//     border: 0 none;
//     color: #495057;
//     background: transparent;
//     transition: box-shadow 0.2s;
//     border-radius: 0;
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
//     color: #495057;
//     background: #e9ecef;
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
//     color: #4338ca;
//     background: #eef2ff;
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
//     margin: 0;
//     padding: 0.75rem 1.25rem;
//     color: #343a40;
//     background: #ffffff;
//     font-weight: 700;
// }

//Just for testing
.searchwrapper {
    z-index: 1011;
    position: absolute;
    right: 50px;
    margin: 0 auto;
    // top: 10px;
    left: 0px;
    right: 0;
    top: 30px;
    max-width: 276px;
    margin-top: 10px;
    -webkit-transition: $transition-base;
    transition: $transition-base;
    @include media-breakpoint-down(sm) {
        left: 0;
        max-width: 130px;
    }
    .search {
        width: 100%;

        top: 0 !important;

        left: 0 !important;

        @include media-breakpoint-down(sm) {
            // max-width: 180px;
        }
        @include media-breakpoint-down(md) {
            // max-width: 215px;
        }
    }
}

//changes postion on searchfield when toggle sidebar
.sidebar-close-search {
    .search {
        left: 0px;
    }
    .searchwrapper {
        -webkit-transition: $transition-base;
        transition: $transition-base;
    }
}
.sidebar-open-search {
    .search {
        -webkit-transition: $transition-base;
        transition: $transition-base;
        @include media-breakpoint-up(sm) {
            left: calc(300px - 1rem);
        }
    }
    .searchwrapper {
        @include media-breakpoint-up(sm) {
            left: calc(300px - 1rem);
        }
    }
}
