.p-field {
    position: relative;
    margin-bottom: 1rem;
    label {
        display: block;
    }
    .p-inputtext {
        width: 100%;
    }
    .p-error {
        position: absolute;
        width: 100%;
        text-align: right;
    }
}

//Styles for the forms label
label {
    // display: block;
    font-family: $font-family-medium;
}
.form-group {
    margin-bottom: $form-group-margin-bottom;
    label {
        text-align: left;
        // margin-left: 0.5rem;
    }
    //her sætter vi inputfelter i .form-group til at være 100% bredde
    .p-inputtext,
    span {
        width: 100%;
    }
}
