.p-splitter {
    // display: -moz-box;
    // display: flex;
    // flex-wrap: nowrap;
    // background-color: black;
    background-color: $white;
    .text-area-wrapper {
        box-shadow: $box-shadow;
        z-index: 1;
    }
    .p-splitter-panel {
        // -moz-box-flex: 1;
        // flex-grow: 1;
        .splitterButtons {
        }
        .p-splitter {
            .p-splitter-gutter {
                background: rgb(226, 224, 218);
                background: linear-gradient(90deg, rgba(226, 224, 218, 1) 0%, rgba(255, 255, 255, 1) 100%);
            }

            .test {
            }
        }
        .p-splitter-horizontal {
        }
    }

    .p-splitter-gutter {
        box-shadow: none;
        .p-splitter-gutter-handle {
            background-color: $black;
            cursor: col-resize;
        }
    }
}

// .p-splitter-vertical {
//     // -moz-box-orient: vertical;
//     // -moz-box-direction: normal;
//     // flex-direction: column;
// }

// .p-splitter-panel-nested {
//     display: -moz-box;
//     display: flex;
// }

// .p-splitter-panel .p-splitter {
//     -moz-box-flex: 1;
//     flex-grow: 1;
//     border: 0;
// }

// .p-splitter-gutter {
//     -moz-box-flex: 0;
//     flex-grow: 0;
//     flex-shrink: 0;
//     display: -moz-box;
//     display: flex;
//     -moz-box-align: center;
//     align-items: center;
//     -moz-box-pack: center;
//     justify-content: center;
//     cursor: col-resize;
// }

// .p-splitter-horizontal.p-splitter-resizing {
//     cursor: col-resize;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// .p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle {
//     height: 24px;
//     width: 100%;
// }

// .p-splitter-horizontal > .p-splitter-gutter {
//     cursor: col-resize;
// }

// .p-splitter-vertical.p-splitter-resizing {
//     cursor: row-resize;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// .p-splitter-vertical > .p-splitter-gutter {
//     cursor: row-resize;
// }

// .p-splitter-vertical > .p-splitter-gutter > .p-splitter-gutter-handle {
//     width: 24px;
//     height: 100%;
// }
