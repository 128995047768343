.p-radiobutton {
    border-radius: 50%;
    .p-radiobutton-box {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        line-height: 1.125em;
        border-radius: 100%;
        text-align: center;
        position: relative;

        border: 1px solid $border-color;

        background-color: $white;
        transition: $transition-base;

        &:hover {
            box-shadow: 0 0 0 0.125rem $primary;
        }
        &:active {
            background-color: $black;
            border: 1px solid $black;
        }
        .p-radiobutton-icon {
            font-size: 0.7rem;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1em;
            height: 1em;
            margin-top: -0.8em;
            margin-left: -0.5em;
        }
    }
    .p-highlight {
        background-color: $black;
        border: 1px solid $black;

        .p-radiobutton-icon:before {
            font-weight: 600;
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            color: $white;
        }
    }
    &.p-radiobutton-disabled {
        .p-radiobutton-box {
            background-color: $gray;
            background-color: 1px solid $gray;
        }
        &:hover {
            cursor: $cursor-disabled;
        }
    }
}
.p-radiobutton-label {
    font-size: 0.8rem;
    font-family: $font-family-medium;
}
.p-radiobutton-label-disabled {
    color: $gray;
    &:hover {
        cursor: $cursor-disabled;
    }
}
