.text-area-wrapper {
    // box-shadow: $box-shadow;
    // border-right: 1px solid $light-gray;
}
.accordion-main-level {
    .p-highlight {
        background-color: $gray-dark !important;
        a,
        .p-accordion-header-text,
        .p-accordion-toggle-icon,
        button {
            color: white !important;
        }

        color: white !important;
        .p-toggleable-content {
            .p-accordion-content {
                .p-accordion {
                    .p-accordion-tab {
                        // background-color: black !important;
                        // color: white;
                    }
                }
            }
        }
        .headerTabTemplate,
        .headerTabTemplate:hover {
            color: white !important;
            background: transparent !important;
        }
    }
    .parentselected {
        background-color: $white !important;
        color: black !important;
        .p-accordion-header {
            border-bottom: none !important;
        }
    }
    .p-accordion-tab {
        .p-accordion-header {
        }
        .p-toggleable-content {
            .p-accordion-content {
            }
        }
    }
    .p-accordion-tab-active {
    }
    .accordionToplevel {
        // background-color: red !important;
        .p-highlight {
            background-color: lighten($gray-dark, 10%) !important;
        }
        .p-accordion-tab-active {
        }
        .accordionThirdlevel.p-highlight {
            background-color: lighten($gray-dark, 30%) !important;
        }
        .accordionThirdlevel.p-highlight.bg-primary {
            background-color: $primary !important;
            color: black !important;
        }
        .accordionThirdlevel {
            .p-accordion-toggle-icon {
                padding-top: 6px !important;
            }
        }
        .p-toggleable-content {
            .p-accordion-content {
                // border-bottom: none;
            }
        }
    }
    .p-accordion-header-text {
        // font-size: small;
        // color: black;
        // font-family: "SwecoSans-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        //     sans-serif;
    }
    .accordionSecondlevel {
        margin-left: 0;
        .p-toggleable-content {
            .p-accordion-content {
                // padding-left: 0;
                // padding-right: 0;
                // border-bottom: none;
            }
            .headerTabTemplate {
                font-size: small;
                color: $black;
                padding-left: 0;
                padding-right: 0;
                border-bottom: none !important;
                .create-scenario {
                    width: 15px;
                    height: 15px;
                    color: $primary;
                    i {
                        width: 15px;
                        height: 15px;
                    }
                }

                .p-button-label {
                    display: none;
                }
            }
        }
    }
    .hideToggleIcon {
        .p-toggleable-content {
            display: none;
        }
        .p-toggleable-content-enter-active {
            display: none;
        }
        .p-toggleable-content-enter-exit {
            display: none;
        }

        .p-accordion-header-link {
            .p-accordion-toggle-icon {
                //display: none !important;
                opacity: 0;
            }
        }
    }
    .headerTabTemplate {
        border-bottom: none !important;
        font-size: small;
        color: black;
        font-family: "SwecoSans-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            sans-serif;
    }
}

.spinner-center {
    position: absolute;
    margin: auto;
    margin-left: 50%;
    margin-top: 25%;
}
.p-splitter .p-splitter-gutter {
    // transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    // background: #f8f9fa;
}
.p-splitter-gutter {
    z-index: 1029;
}
.content-office {
    height: calc(100vh - 50px);
    padding-top: 43px;
}

.p-datatable-joinworkarea {
    .p-datatable-wrapper {
        .p-datatable-table {
            .p-datatable-thead {
                tr {
                    .p-align-right {
                        .p-column-header-content {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

//Test for making equal height columns in bootstrap
.row-flex {
    display: flex;
    flex-wrap: wrap;
}

.content1 {
    height: 100%;
    padding-bottom: 30px;
}
