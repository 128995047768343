.p-panelmenu {
    .p-panelmenu-panel {
        margin-bottom: 5px;
        .p-panelmenu-header {
            font-size: $font-size-base;
            padding: 10px;
            text-transform: uppercase;
            font-family: $font-family-medium;
            transition: $transition-base;
            .p-panelmenu-header-link {
                // display: block;
                color: $gray-dark;
                transition: $transition-base;
                .p-panelmenu-icon {
                    // font-size: 1rem;
                    // font-weight: 600;
                    // font-family: "Font Awesome 5 Free";
                    margin-right: 5px;
                    color: $gray-dark;
                    display: flex;
                    transition: $transition-base;
                    // float: right;
                }
                .pi-chevron-down {
                }
                .p-menuitem-text {
                }
            }
            &.p-highlight {
                background-color: $dark;
                color: $white;
                .p-panelmenu-icon,
                .p-panelmenu-header-link {
                    color: $white;
                }
            }
            &:hover {
                background-color: $dark;
                color: $white;
                .p-panelmenu-icon,
                .p-panelmenu-header-link {
                    color: $white;
                }
            }
        }
        .p-toggleable-content {
            margin: 0;
            .p-panelmenu-content {
                .p-submenu-list {
                    .p-menuitem {
                        border-bottom: 1px solid $border-color;
                        .p-menuitem-link {
                            font-size: $font-size-base;
                            padding: 10px;
                            text-transform: uppercase;
                            font-family: $font-family-medium;
                            padding-left: 2rem;
                        }
                        &:hover {
                            transition: $transition-base;
                            background-color: $gray-lighter;
                        }
                    }
                }
            }
        }
    }
}
