.p-progress-spinner {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    .p-progress-spinner-svg {
        width: 50px;

        .p-progress-spinner-circle {
            animation: ui-progress-spinner-color 0.75s linear infinite;
        }
    }
}

@keyframes ui-progress-spinner-color {
    100%,
    0% {
        stroke: black;
    }
    40% {
        stroke: black;
    }
    66% {
        stroke: black;
    }
    80%,
    90% {
        stroke: black;
    }
}

.spinner-sweco {
    transform-origin: center;
    animation: spinner-sweco-anim 0.75s infinite linear;
}
@keyframes spinner-sweco-anim {
    100% {
        transform: rotate(360deg);
    }
}
.waiting-spinner {
    // width: 100%;
    // height: 100%;
    // left: 0;
    // position: absolute;
    // right: 0;
    // top: 0;
    // bottom: 0;
}

.spinner-border {
    border-color: #000;
    border-width: 0.2rem;
    border-bottom: 0.2rem solid transparent !important;
    border-left: 0.2rem solid transparent !important;
    border-right: 0.2rem solid transparent !important;
    line-height: 0;

    &::before {
        border: 0.2rem solid #000;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 2rem;
        margin: -0.2rem;
        opacity: 0.25;
        width: 2rem;
    }

    &.spinner-border-white,
    .spinner-border-white {
        border-color: #fff;
        &::before {
            border-color: #fff;
        }
    }
    &.spinner-border-sm {
        border-width: 0.1rem;
        border-bottom: 0.1rem solid transparent !important;
        border-left: 0.1rem solid transparent !important;
        border-right: 0.1rem solid transparent !important;
        height: 1.25rem;
        vertical-align: text-top;
        width: 1.25rem;
        &::before {
            border-width: 0.1rem !important;
            height: 1.25rem;
            margin: -0.1rem;
            width: 1.25rem;
        }
    }
}

.spinner-border.spinner-border-white,
.spinner-border.spinner-border-white:before {
    // border-color: #fff;
}

.spinner-border.spinner-border-sm {
    // border-width: 0.1rem;
    // border-bottom: 0.1rem solid transparent !important;
    // border-left: 0.1rem solid transparent !important;
    // border-right: 0.1rem solid transparent !important;
    // height: 1.25rem;
    // vertical-align: text-top;
    // width: 1.25rem;
}

.spinner-border.spinner-border-sm:before {
    // border-width: 0.1rem !important;
    // height: 1.25rem;
    // margin: -0.1rem;
    // width: 1.25rem;
}
