.scenarios-area-wrapper {
    height: calc(100vh - 89px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    //Styling til accordion på sidebaren- Bliver kun brugt her
    //Level 0 scenarios
    .p-accordion {
        //Level 1 open scenarios accordion-main-level
        .p-accordion-tab {
            .p-accordion-header {
                .p-accordion-header-link {
                    padding: 0.25rem 0rem 0.25rem 0rem;
                    text-transform: uppercase;
                    .p-accordion-toggle-icon {
                        width: 30px;
                        text-align: center;
                    }
                }

                .pi-chevron-right:before {
                    font-family: $font-awesome;
                    content: "\f3be";
                    font-weight: $font-awesome-weight;
                }
                .pi-chevron-down:before {
                    font-family: $font-awesome;
                    content: "\f00d";
                    font-weight: $font-awesome-weight;
                }
            }
            .p-toggleable-content {
                .p-accordion-content {
                    .p-accordion {
                        //Level 2 open scenarios accordionToplevel
                        .p-accordion-tab {
                            .p-accordion-header {
                            }
                            .p-toggleable-content {
                                .p-accordion-content {
                                    .p-accordion {
                                        .p-accordion-tab {
                                            .p-accordion-header {
                                                //Level 3 open scenarios accordionThirdlevel
                                                .p-accordion-header-link {
                                                    padding-left: 0;
                                                    button {
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //Level 1 open scenarios
        .p-accordion-tab-active {
            .p-accordion-header {
                .p-accordion-header-link {
                    button {
                        color: $white;
                    }
                }
            }
            .p-toggleable-content {
                .p-accordion-content {
                    padding-left: 0;
                    padding-top: 0;
                    padding-right: 0;
                    padding-bottom: 0;

                    border-bottom: none;
                    .p-accordion {
                        background-color: darken($gray-lighter, 10%);

                        //Level 2 open scenarios accordionThirdlevel
                        .p-accordion-tab {
                            .p-accordion-header {
                                .p-accordion-header-link {
                                    // background-color: red;
                                    font-size: 0.75rem;
                                    text-transform: none;
                                    padding: 0.25rem 0rem 0.25rem 2.1rem;
                                    button {
                                        // color: $black;
                                    }
                                }
                            }
                        }
                        .p-accordion-tab-active {
                            .p-accordion-header {
                                .p-accordion-header-link {
                                    button {
                                        color: $green;
                                    }
                                }
                            }
                            // Lev 3
                            .p-toggleable-content {
                                .p-accordion-content {
                                    .p-accordion {
                                        .p-accordion-tab {
                                            .p-accordion-header {
                                                .p-accordion-header-link {
                                                    button {
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                        .p-accordion-tab-active {
                                            .p-accordion-header {
                                                .p-accordion-header-link {
                                                    button {
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .accordionThirdlevel {
            button {
                // color: $black;
            }
        }
    }
    .p-accordion-tab-active {
        .accordionThirdlevel.bg-primary {
            // background-color: $red !important;
        }
    }
    .p-accordion-tab-selected-lev3 {
        background-color: $red !important;
    }
    .p-accordion-tab-selected-lev4 {
        background-color: $green !important;
    }
    .remove-icon {
        .p-accordion-header-link {
            .p-accordion-toggle-icon {
                display: none;
            }
        }
    }
}
