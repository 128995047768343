// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables

// White
$white: #fff;

// Red, validation and states
$red: #821307;

// Green, validation and states
$green-validation: #7a9b62;

$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// NYE SWECOFARVER 05-10-2022

// Sweco Grayscale
$black: #000000;
$gray-dark: #3f3f42;
$gray: #a4a4a6;
$gray-light: #e2e0da;
$gray-lighter: #f3f3f0;

// Sweco Green
$green-dark: #7a9b62;
$green: #9dd354;
$green-light: #c4e598;
$green-lighter: #e6f4d4;

// Sweco Blue
$blue-dark: #8595af;
$blue: #c0d4fd;
$blue-light: #d9e5fe;
$blue-lighter: #eff4fe;

// Sweco Ochre
$ochre-dark: #a48730;
$ochre: #dec55b;
$ochre-light: #ebdc9d;
$ochre-lighter: #f7f0d6;

// Sweco Validation
$validation-alert: #821307;
$validation-success: #7a9b62;

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    // "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $green;
$secondary: $white;
$info: $ochre;
$success: $validation-success; // Validation and states
$warning: $red; // Validation and states
$danger: $validation-alert; // Validation and states
$light: $gray-light;
$dark: $gray-dark;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
);
// scss-docs-end theme-colors-map
// Colors (Bootstrap 3 scss)
$brand-primary: $green;
$brand-secondary: $white;
$brand-info: $ochre;
$brand-warning: $red; // Validation and states
$brand-danger: $red; // Validation and states
$brand-success: $green; // Validation and states
$brand-inverse: $gray-dark;
$brand-dark: $dark;
$brand-light: $light;
// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

// $pink-100: tint-color($pink, 80%) !default;
// $pink-200: tint-color($pink, 60%) !default;
// $pink-300: tint-color($pink, 40%) !default;
// $pink-400: tint-color($pink, 20%) !default;
// $pink-500: $pink !default;
// $pink-600: shade-color($pink, 20%) !default;
// $pink-700: shade-color($pink, 40%) !default;
// $pink-800: shade-color($pink, 60%) !default;
// $pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0)) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
    0: 0,
    50: 50%,
    100: 100%,
) !default;
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-lighter;
$body-color: $gray-900 !default;
$body-text-align: null !default;
$text-color: $body-color;
// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: $green;
$link-hover-decoration: underline;

// Paragraphs
$paragraph-margin-bottom: $spacer;

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 8px;
// $grid-gutter-width: 30px;

// Components
$border-width: 1px;
$border-color: $gray;
$border-color-light: $gray-lighter;

$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;

$component-color: $text-color;
$component-bg-hover: $gray-lighter;
$component-active-color: $white;
$component-active-bg: $gray-dark;
$component-active-hover-bg: darken($gray-dark, 5%);

$caret-width: 0.3em;

// Transition for btn, nav and such
$transition-base: all 0.3s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.3s ease;
$transition-bg: background-color 0.3s ease-in-out;
$transition: $transition-base; // BS 3 scss

// Cursor
$cursor-disabled: not-allowed;

// Fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-regular: "SwecoSans-Regular", $font-family-sans-serif;
$font-family-medium: "SwecoSans-Medium", $font-family-sans-serif;
$font-family-heavy: "SwecoSans-Bold", $font-family-sans-serif;
$font-family-base: $font-family-regular;
$font-awesome: "Font Awesome 5 Free";
$font-awesome-weight: 900;

// Font-size for scale all typography
$font-size-base: 1rem;
$font-size-xl: 1.6rem;
$font-size-lg: 1.3rem;
$font-size-sm: 0.7rem;
$font-size-xs: 0.6rem;

$line-height-base: 1.5;
$line-height-xl: 1.6;
$line-height-lg: 1.45;
$line-height-sm: 1.2;
$line-height-xs: 1.45;
$line-height-computed: 1.25rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.75;

$headings-line-height: 1.26;
$h1-line-height: 2.625rem;
$h2-line-height: 2.5rem;
$h3-line-height: 1.75rem;
$h4-line-height: 1.25rem;
$h5-line-height: 1rem;
$h6-line-height: 0.75rem;

$headings-margin-bottom: ($spacer);
$headings-font-family: $font-family-medium;
$headings-font-weight: $font-weight-normal;
$headings-color: $black;
// Display
$display1-size: 6rem;
$display2-size: 5.35rem;
$display3-size: 4rem;
$display4-size: 2.7rem;

$display1-weight: $font-weight-light;
$display2-weight: $font-weight-light;
$display3-weight: $font-weight-light;
$display4-weight: $font-weight-light;

$display-line-height: $headings-line-height;
$line-height-display-1: 1.17;
$line-height-display-2: 1.19;
$line-height-display-3: 1.25;
$line-height-display-4: 1.37;

// Lead
$lead-font-size: 1.25rem;
$lead-line-height: 1.24;
$lead-font-weight: $font-weight-normal;

// Small
$small-font-size: 80%;

// Mute & disabled
$text-muted: $gray;
$disabled-color: $text-muted;
$disabled-bg: $gray-lighter;

// Abbr
$abbr-border-color: $gray;

$blockquote-small-color: $text-muted;
$blockquote-font-size: $font-size-lg;

// Hr
$hr-border-color: $border-color;
$hr-border-width: $border-width;
$hr-margin-y: $spacer;

// Mark
$mark-padding: 0.2em 0.1em;
$mark-bg: $gray-light;
// scss-docs-end type-variables
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color;
$table-bg: $white;
$table-accent-bg: transparent !default;

$table-th-font-weight: null !default;

$table-striped-color: $table-color;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-striped-order: odd !default;

$table-group-separator-color: currentColor !default;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
// $table-variants: (
//   "primary":    shift-color($primary, $table-bg-scale),
//   "secondary":  shift-color($secondary, $table-bg-scale),
//   "success":    shift-color($success, $table-bg-scale),
//   "info":       shift-color($info, $table-bg-scale),
//   "warning":    shift-color($warning, $table-bg-scale),
//   "danger":     shift-color($danger, $table-bg-scale),
//   "light":      $light,
//   "dark":       $dark,
// ) !default;

// scss-docs-end table-loop
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.1rem;
$input-btn-padding-x: 0.5rem;
$input-btn-font-family: null !default;
$input-btn-font-size: 0.8rem;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.25rem;
$input-btn-padding-x-lg: 0.5rem;
$input-btn-font-size-lg: $font-size-base;

$input-btn-border-width: $border-width !default;
// scss-docs-end input-btn-variables

// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: null !default;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: 0.8rem;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg: $white;
$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: $disabled-color;

$input-color: $body-color !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
// $input-box-shadow: $box-shadow-inset !default;

$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-lg !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5) !default;

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-color-width: 3rem !default;
// scss-docs-end form-input-variables

// Kbd
$kbd-color: $white;
$kbd-bg: $black;
$kbd-box-shadow: none;
$nested-kbd-font-weight: normal;

// Lists
$dt-font-weight: normal;
$dt-font-weight: $font-weight-bold;
$list-inline-padding: 0.5rem;

// Mute & disabled
$text-muted: $gray;
$disabled-color: $black;
$disabled-bg: $gray-lighter;

// // Links
// $link-color: $pink-dark;
// $link-decoration: none;
// $link-hover-color: $link-hover-color;
// $link-hover-decoration: underline;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

// scss-docs-start border-radius-variables
$border-radius: 0.25rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
// Highlight & shadows
$box-shadow-size: 15px;
$box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
// $box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
// $box-shadow-inset: 0 1px 2px rgba($black, 0.075) !default;
// scss-docs-end box-shadow-variables

$component-active-color: $white !default;
$component-active-bg: $primary !default;

// scss-docs-start caret-variables
$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;
// scss-docs-end caret-variables

// Transition for btn, nav and such
$transition-base: all 0.3s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.3s ease;
$transition-bg: background-color 0.3s ease-in-out;
$transition: $transition-base; // BS 3 scss

// Highlight & shadows

// $box-shadow: rgba(0, 0, 0, 0.3);
// $box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.3);
// $box-shadow: 0px 0px 15px 0px rgba(0 0 0 / 60%);
// $box-shadow-lg: 0 1rem 3rem rgba($black, 0.3);

// Navs
$nav-link-padding-y: 0.6rem;
$nav-link-padding-x: $spacer;

$nav-link-color: $text-color;
$nav-link-hover-color: darken($text-color, 10%);
$nav-link-hover-bg: $component-bg-hover;
$nav-text-transform: uppercase;

$nav-border-color: $border-color-light;

$nav-disabled-link-color: $disabled-color;
$nav-disabled-link-hover-color: $disabled-color;
$nav-disabled-link-hover-bg: transparent;

$nav-tabs-border-color: $border-color;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent transparent $border-color transparent;
$nav-tabs-link-active-color: $component-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $border-color $border-color transparent $border-color;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar
$navbar-bg: rgba(0, 0, 0, 0.77); // @black-ich;
$navbar-color: $white;
$navbar-height: 60px;
$navbar-north-height: $navbar-height;
$navbar-south-height: $navbar-height;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: math.div($spacer, 2);
$navbar-brand-font-size: $font-size-base;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: math.div(($font-size-base * $line-height-base + $nav-link-padding-y), 2);
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: math.div(($nav-link-height - $navbar-brand-height), 2);

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: $disabled-color;
$navbar-dark-toggler-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
    "#",
    "%23"
);
$navbar-dark-toggler-border-color: transparent;

$navbar-light-color: $white;
$navbar-light-hover-color: $white;
$navbar-light-active-color: $white;
$navbar-light-disabled-color: $disabled-color;
$navbar-light-toggler-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
    "#",
    "%23"
);
$navbar-light-toggler-border-color: transparent;
$zindex-navbar: 1010;

$dropdown-padding-x: $spacer * 0.5;
$dropdown-padding-y: $spacer * 0.5;

// Navs
$nav-link-padding-y: 0.6rem;
$nav-link-padding-x: $spacer;

$nav-link-color: $text-color;
$nav-link-hover-color: darken($text-color, 10%);
$nav-link-hover-bg: $component-bg-hover;
$nav-text-transform: uppercase;

$nav-border-color: $border-color-light;

$nav-disabled-link-color: $disabled-color;
$nav-disabled-link-hover-color: $disabled-color;
$nav-disabled-link-hover-bg: transparent;

$nav-tabs-border-color: $border-color;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent transparent $border-color transparent;
$nav-tabs-link-active-color: $component-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $border-color $border-color transparent $border-color;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar
$navbar-bg: rgba(0, 0, 0, 0.77); // @black-ich;
$navbar-color: $white;
$navbar-height: 60px;
$navbar-north-height: $navbar-height;
$navbar-south-height: $navbar-height;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: calc($spacer / 2);
$navbar-brand-font-size: $font-size-base;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2);
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: math.div(($nav-link-height - $navbar-brand-height), 2);

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: $disabled-color;
$navbar-dark-toggler-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
    "#",
    "%23"
);
$navbar-dark-toggler-border-color: transparent;

$navbar-light-color: $white;
$navbar-light-hover-color: $white;
$navbar-light-active-color: $white;
$navbar-light-disabled-color: $disabled-color;
$navbar-light-toggler-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
    "#",
    "%23"
);
$navbar-light-toggler-border-color: transparent;

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0;
$form-label-font-size: 0.8rem;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: null !default;

$form-group-margin-bottom: 0.5rem;
// scss-docs-end form-label-variables
// Pagination
$pagination-border-radius: 50%;
$pagination-spacing: calc($spacer / 2);

$pagination-padding-y: 0.6rem;
$pagination-padding-x: 0.94rem;
$pagination-padding-y-sm: 0.4rem;
$pagination-padding-x-sm: 0.73rem;
$pagination-padding-y-lg: 0.65rem;
$pagination-padding-x-lg: 1.1rem;

$pagination-line-height: 1.25;

$pagination-color: $text-color;
$pagination-bg: transparent;
$pagination-border-width: $border-width;
$pagination-border-color: transparent;

// $pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: $border-color;

$pagination-active-color: $text-color;
$pagination-active-bg: transparent;
$pagination-active-border-color: $border-color;

$pagination-disabled-color: $disabled-color;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;

// Jumbotron
$jumbotron-padding: 2rem;
$jumbotron-bg: $blue-dark;
$jumbotron-color: $white;

// Cards
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: $border-color;
$card-inner-border-radius: 0;
$card-cap-bg: $disabled-bg;
$card-bg: $white;

$card-img-overlay-padding: $spacer;

$card-group-margin: calc($grid-gutter-width / 2);
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Tooltips
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: rgba(0, 0, 0, 0.77); // @black-ich;
$tooltip-opacity: 1;
$tooltip-padding-y: 5px;
$tooltip-padding-x: 10px;
$tooltip-margin: 1px;
$tooltip-arrow-width: 10px;
$tooltip-arrow-height: 5px;
$tooltip-arrow-color: $tooltip-bg;

// Popovers
$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 280px;
$popover-border-width: 0;
$popover-border-radius: 0;
$popover-border-color: transparent;
$popover-box-shadow: 0 5px $box-shadow-size $box-shadow;

$popover-header-bg: $brand-primary;
$popover-header-color: $white;
$popover-header-padding-y: 9px;
$popover-header-padding-x: $spacer;

$popover-body-color: $text-color;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;
$popover-body-max-height: 280px;

$popover-arrow-width: 16px;
$popover-arrow-height: 8px;
$popover-arrow-color: $white;
$popover-arrow-outer-width: 9px;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Badges

// scss-docs-start badge-variables
// $badge-font-size:                   .75em !default;
// $badge-font-weight:                 $font-weight-bold !default;
// $badge-color:                       $white !default;
// $badge-padding-y:                   .35em !default;
// $badge-padding-x:                   .65em !default;
// $badge-border-radius:               $border-radius !default;

// Badges
$badge-color: $white;
$badge-font-size: 0.85rem;
$badge-font-weight: normal;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.5em;
$badge-border-radius: $border-radius;
$badge-pill-padding-x: 0.6em;
$badge-pill-border-radius: 10rem;

// $badge-font-size:                   .75em !default;
// $badge-font-weight:                 $font-weight-bold !default;
// $badge-color:                       $white !default;
// $badge-padding-y:                   .35em !default;
// $badge-padding-x:                   .65em !default;
// $badge-border-radius:               $border-radius !default;

// $badge-default-bg: $disabled-color;
// $badge-primary-bg: $primary;
// $badge-success-bg: $success;
// $badge-info-bg: $info;
// $badge-warning-bg: $warning;
// $badge-danger-bg: $danger;
// scss-docs-end badge-variables

// Modals
$modal-inner-padding: 1rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 90px;

$modal-title-line-height: $line-height-base;

// $modal-content-bg: $white;
$modal-content-bg: $white;

$modal-content-border-color: $border-color;
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: 0 5px $box-shadow;
$modal-content-box-shadow-sm-up: 0 5px $box-shadow-size $box-shadow;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.8;

$modal-header-bg: $dark;
$modal-header-color: $white;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
// $modal-header-padding: 1.035rem $spacer;
$modal-header-padding: 0.5rem 0.5rem 0.5rem 1rem;

$modal-transition: transform 0.3s ease-out;

// Alerts
$alert-padding-y: 0.5rem;
$alert-padding-x: 0.5rem;
$alert-margin-bottom: $spacer;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_mixins";
@import "~bootstrap/scss/_variables";
