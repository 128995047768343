@use "sass:math";

//Custom
@import "./abstracts/_variables.scss";

@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

@import "../vendors/fontawesome-free-5.6.3-web/scss/fontawesome.scss";
@import "../vendors/fontawesome-free-5.6.3-web/scss/solid.scss";
@import "../vendors/fontawesome-free-5.6.3-web/scss/regular.scss";

@import "../vendors/primereact/primereact.scss";
@import "../vendors/sweco/sweco.scss";
@import "../vendors/amaisy/scenariosection.scss";
@import "../vendors/amaisy/errormessages.scss";
@import "./components/_maps.scss";
@import "./amaisyoffice.scss";
@import "../vendors/amaisy/attributeview.scss";
@import "../vendors/amaisy/tabview.scss";
@import "../vendors/amaisy/iconandlabel.scss";
@import "../vendors/amaisy/spinner.scss";
@import "../vendors/ol/ol.scss";
// html,
// body,
// #container {
//     height: 100%;
// }
