.labelGeometry {
    margin-top: 60px;
    margin-left: 50px;
    margin-bottom: 50px;
}
.iconMainPos {
    margin-left: 32px;
    margin-bottom: 30px;
}
.iconPosSize {
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
}

.attributeTableIcon {
    .pointer {
        cursor: pointer;
    }
}

.iconMargin {
    margin-left: 10px;
}
