.p-confirm-dialog {
    .p-dialog-header {
    }
    .p-dialog-content {
        padding: 2rem;
        display: block;
        text-align: center;
        .p-confirm-dialog-icon {
            font-size: 3rem;
            display: block;
        }
    }
    .p-resizable-handle {
        display: none;
    }
}
