.p-button {
    padding: $input-padding-y $input-padding-x;
    font-family: $font-family-medium;
    // font-weight: $btn-font-weight;
    border-width: $btn-border-width;
    margin-right: 0;
    border-style: unset;
    font-size: $input-btn-font-size;
    transition: $transition-base;
    line-height: inherit;
    background-color: $primary;
    color: $black;

    border: $btn-border-width solid darken($primary, 10%);
    .p-button-text {
        // padding: $input-padding-y $input-padding-x;
        line-height: inherit;
        padding: 0;
    }
    .p-button-icon-left {
        padding-right: 5px;
    }
    .p-button-icon-right {
        padding-left: 5px;
    }
    .pi {
        color: $white;
    }
    &:hover {
        transition: $transition-base;
        box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.25);
        background-color: darken($primary, 10%);
        border-color: darken($primary, 10%);
    }
    &:focus {
        transition: $transition-base;
        outline: none;
        background-color: darken($primary, 10%);
        border-color: darken($primary, 10%);
    }
    &:active {
        transition: $transition-base;
        border-style: solid $btn-border-width $primary;
        background-color: darken($primary, 10%);
        border-color: darken($primary, 10%);
    }
    &.p-button-icon-only {
        // padding-left: 1rem;
        // padding-right: 1rem;
        .p-button-icon {
            min-width: 1rem;
        }
    }
    &.p-button-icon-only-xxl {
        // padding: 0.1rem;
        .p-button-icon {
            // font-size: 3rem; //same as fa-3x
        }
    }
    &.p-button-text-icon-right {
        .p-button-text {
            padding-right: 20px;
        }
    }
    &.p-button-text-icon-left {
        .p-button-text {
            padding-left: 20px;
        }
    }
}
.p-button-dark,
.p-confirm-dialog-reject {
    background-color: $dark;
    color: $white;
    border-color: $dark;
    border: $border-width solid $dark;
    background-color: $dark;
    color: $white;
    border-color: $dark;
    border: $border-width solid $dark;

    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($dark, 0.25);
        background-color: darken($dark, 10%);
        border-color: darken($dark, 10%);
        color: darken($white, 10%);
    }
    &:focus {
        outline: none;
        background-color: darken($dark, 10%);
        color: darken($white, 10%);
        border-color: darken($dark, 10%);
    }
    &:active {
        border-style: solid $border-width $dark;
        background-color: darken($dark, 10%);
        color: darken($white, 10%);
        border-color: darken($dark, 10%);
    }
}
.p-button-light {
    background-color: $light;
    color: $dark;
    border-color: $light;
    border: $btn-border-width solid $light;
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($light, 0.25);
        background-color: darken($light, 10%);
        border-color: darken($light, 10%);
    }
    &:focus {
        outline: none;
        background-color: darken($light, 10%);
        border-color: darken($light, 10%);
    }
    &:active {
        border-style: solid $border-width $light;
        background-color: darken($light, 10%);
        border-color: darken($light, 10%);
    }
}

.p-button-primary {
    background-color: $primary;
    color: $black;
    // border-color: $blue-dark;
    border: $border-width solid $primary;

    // &:hover {
    //     box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.25);
    //     background-color: darken($primary, 10%);
    //     border-color: darken($primary, 10%);
    // }
    // &:focus {
    //     outline: none;
    //     background-color: darken($blue-dark, 10%);
    //     border-color: darken($blue-dark, 10%);
    // }
    // &:active {
    //     border-style: solid $border-width $primary;
    //     background-color: darken($blue-dark, 10%);
    //     border-color: darken($blue-dark, 10%);
    // }
}
.p-button-secondary {
    background-color: $secondary;
    color: $text-color;
    border-color: $dark;

    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($dark, 0.25);
        background-color: darken($black, 10%);
        border-color: darken($black, 10%);
        color: $white;
    }
    &:focus {
        outline: none;
        background-color: darken($black, 10%);
        border-color: darken($black, 10%);
        color: $white;
        // box-shadow: 0 0 0 $input-btn-focus-width rgba($dark, 0.25);
    }
    &:active {
        border-style: solid $border-width $secondary;
        background-color: darken($secondary, 10%);
        border-color: darken($secondary, 10%);
        color: $black;
    }
    .p-button-icon-left {
        color: $text-color;
    }
}
.p-button-success {
    background-color: $success;
    color: $white;
    border-color: $success;
    border: $btn-border-width solid $success;
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($success, 0.25);
        background-color: darken($success, 10%);
        border-color: darken($success, 10%);
    }
    &:focus {
        outline: none;
        background-color: darken($success, 10%);
        border-color: darken($success, 10%);
    }
    &:active {
        border-style: solid $btn-border-width $success;
        background-color: darken($success, 10%);
        border-color: darken($success, 10%);
    }
}
.p-button-danger {
    background-color: $danger;
    color: $white;
    border-color: $danger;
    border: $border-width solid $danger;
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, 0.25);
        background-color: darken($danger, 10%);
        border-color: darken($danger, 10%);
    }
    &:focus {
        outline: none;
        background-color: darken($danger, 10%);
        border-color: darken($danger, 10%);
    }
    &:active {
        border-style: solid $btn-border-width $danger;
        background-color: darken($danger, 10%);
        border-color: darken($danger, 10%);
    }
}
.p-button-info {
    background-color: $info;
    color: $white;
    border-color: $info;
    border: $border-width solid $info;
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($info, 0.25);
        background-color: darken($info, 10%);
        border-color: darken($info, 10%);
    }
    &:focus {
        outline: none;
        background-color: darken($info, 10%);
        border-color: darken($info, 10%);
    }
    &:active {
        border-style: solid $border-width $info;
        background-color: darken($info, 10%);
        border-color: darken($info, 10%);
    }
}
.p-button-warning {
    background-color: $warning;
    color: $white;
    border-color: $warning;
    border: $border-width solid $warning;
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($warning, 0.25);
        background-color: darken($warning, 10%);
        border-color: darken($warning, 10%);
    }
    &:focus {
        outline: none;
        background-color: darken($warning, 10%);
        border-color: darken($warning, 10%);
    }
    &:active {
        border-style: solid $border-width $warning;
        background-color: darken($warning, 10%);
        border-color: darken($warning, 10%);
    }
}

.p-button-link {
    background-color: transparent;
    color: $link-color;
    border-color: transparent;
    border: $btn-border-width solid transparent;
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($link-color, 0.25);
        background-color: darken(transparent, 10%);
        border-color: darken(transparent, 10%);
    }

    &:focus {
        outline: none;
        // box-shadow: 0 0 0 $input-btn-focus-width rgba($link-color, 0.25);
        background-color: darken(transparent, 10%);
        border-color: darken(transparent, 10%);
    }
    &:active {
        border-style: solid $btn-border-width $link-color;
        background-color: darken(transparent, 10%);
        border-color: darken(transparent, 10%);
    }
}
.p-button.p-disabled {
    background-color: $disabled-bg;
    color: $gray;
    border-color: $disabled-bg;
    border: $btn-border-width solid $disabled-bg;
    & button:active {
        border-style: solid $border-width $danger;
    }
    &:hover {
        cursor: not-allowed !important;
    }
}
.p-button-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    // @include button-size(
    //     $btn-padding-y-xs,
    //     $btn-padding-x-xs,
    //     $btn-font-size-sm,
    //     $btn-line-height-xs,
    //     $btn-border-radius-sm
    // );
}
.p-button-lg {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-base;
    // @include button-size(
    //     $btn-padding-y-lg,
    //     $btn-padding-x-lg,
    //     $btn-font-size-lg,
    //     $btn-line-height-lg,
    //     $btn-border-radius-lg
    // );
}

.btn-only-icon {
    background-color: transparent;
    // color: $link-color;
    border-color: transparent;
    border: $btn-border-width solid transparent;
    &:hover {
        // box-shadow: 0 0 0 $input-btn-focus-width rgba($link-color, 0.25);
        background-color: darken(transparent, 10%);
        border-color: darken(transparent, 10%);
    }

    &:focus {
        // outline: none;
        // // box-shadow: 0 0 0 $input-btn-focus-width rgba($link-color, 0.25);
        background-color: darken(transparent, 10%);
        border-color: darken(transparent, 10%);
    }
    &:active {
        // border-style: solid $btn-border-width $link-color;
        background-color: darken(transparent, 10%);
        border-color: darken(transparent, 10%);
    }
    &:disabled {
        background-color: transparent;
        border-color: transparent;
    }
}

.fa-fw {
    // padding: 0 0.8rem;
}
