// Fonts

// Sweco Sans Regular
@font-face {
    font-family: "SwecoSans-Regular";
    src: url("../../graphics/fonts/SwecoSans-Regular.eot");
    src: url("../../graphics/fonts/SwecoSans-Regular.woff2") format("woff2"),
        url("../../graphics/fonts/SwecoSans-Regular.woff") format("woff"),
        url("../../graphics/fonts/SwecoSans-Regular.ttf") format("truetype"),
        url("../../graphics/fonts/SwecoSans-Regular.svg#SwecoSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

// Sweco Sans Medium
@font-face {
    font-family: "SwecoSans-Medium";
    src: url("../../graphics/fonts/SwecoSans-Medium.eot");
    src: url("../../graphics/fonts/SwecoSans-Medium.woff2") format("woff2"),
        url("../../graphics/fonts/SwecoSans-Medium.woff") format("woff"),
        url("../../graphics/fonts/SwecoSans-Medium.ttf") format("truetype"),
        url("../../graphics/fonts/SwecoSans-Medium.svg#SwecoSans-Medium") format("svg");
    font-weight: normal;
    font-style: normal;
}

// Sweco Sans Bold
@font-face {
    font-family: "SwecoSans-Bold";
    src: url("../../graphics/fonts/SwecoSans-Bold.eot");
    src: url("../../graphics/fonts/SwecoSans-Bold.woff2") format("woff2"),
        url("../../graphics/fonts/SwecoSans-Bold.woff") format("woff"),
        url("../../graphics/fonts/SwecoSans-Bold.ttf") format("truetype"),
        url("../../graphics/fonts/SwecoSans-Bold.svg#SwecoSans-Bold") format("svg");
    font-weight: normal;
    font-style: normal;
}
