.p-progressbar {
    background-color: $primary;
    height: 15px;
    width: 100%;
    .p-progressbar-value {
        background-color: $success;
    }
    .p-progressbar-label {
        font-size: 10px;
    }
}
