.p-datatable {
    padding: 0.5rem;
    background-color: $white;
    color: $body-color;
    transition: $transition-base;
    .p-datatable-header {
        text-align: right;
        margin-bottom: 0.25rem;
    }
    .p-datatable-wrapper {
        table.p-datatable-table {
            .p-datatable-thead {
                background-color: $white;
                tr {
                    border: 1px solid $gray-light;
                    // border-bottom: none;
                    th {
                        border: none;
                        .p-column-header-content {
                            .p-column-filter {
                                .p-column-filter-menu-button {
                                    span::before {
                                        font-family: $font-awesome;
                                        font-weight: $font-awesome-weight;
                                        font-size: 0.8rem;
                                        padding-left: 0.5rem;
                                        padding-right: 0.5rem;

                                        content: "\f0b0";
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .p-datatable-tbody {
                tr {
                    border-bottom: 1px solid $gray-light;
                }

                .p-selectable-row:hover {
                    background-color: $gray;
                }
            }
        }
    }
    // .p-datatable-wrapper {
    //     border: 1px solid $border-color;
    table.p-datatable-table {
        border: 0;
        vertical-align: middle;
        // border: 1px solid $gray-lighter;
        @include media-breakpoint-down(sm) {
            table-layout: auto !important;
        }
        .p-datatable-thead {
            // border: 1px solid $gray-superlight;

            tr {
                @include media-breakpoint-down(sm) {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    td {
                        word-break: break-all;
                    }
                }
                th {
                    vertical-align: top;
                    border: 1px solid $gray-lighter;
                    input,
                    .p-placeholder,
                    .p-multiselect-label,
                    .p-dropdown-label,
                    .p-multiselect-item {
                        width: 100%;
                        font-family: $font-family-regular;
                    }
                    .p-column-title {
                        // text-transform: uppercase;
                        font-size: $font-size-sm;
                        display: block;
                        font-family: $font-family-regular;
                        font-weight: 100;
                    }
                    .p-component {
                        // font-size: $font-size-sm;
                        .p-placeholder {
                            height: auto;
                        }
                    }
                    .p-inputtext {
                        padding: $input-padding-y-sm $input-padding-x-sm;
                        // font-size: $custom-select-font-size-sm;
                    }
                }
                // th:hover {
                //     background-color: $table-hover-bg;
                // }
                th.p-highlight {
                    background-color: $gray-dark;
                    .p-column-title {
                        color: $white;
                        font-family: $font-family-regular;
                    }
                    .p-sortable-column-icon,
                    .pi-filter-icon {
                        color: $white;
                    }
                }

                .p-sortable-column {
                    position: relative;
                    vertical-align: top;
                    .p-column-title {
                        vertical-align: top;
                        // margin-bottom: 23px;
                        // display: block;
                        padding-right: 5px;
                    }
                    .p-sortable-column-icon {
                        // position: absolute;
                        right: 0;
                        top: 5px;
                        vertical-align: 0;
                    }
                    .p-inputtext {
                        right: 0px;
                        left: 0;
                        margin: 0 auto;
                        position: absolute;
                        bottom: 2px;
                        width: calc(100% - 5px);
                    }
                    .p-sortable-column-icon {
                        right: 2px;
                        &.pi-sort-alt:before {
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\F0dc";
                        }
                        &.pi-sort-amount-up-alt:before {
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\f0de";
                        }
                        &.pi-sort-amount-down:before {
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\F0dd";
                        }
                    }
                }
                .p-resizable-column {
                    .p-column-resizer {
                        // background-color: red;
                        width: 2px;
                    }
                }
            }
        }
        tbody.p-datatable-tbody {
            tr {
                transition: $transition-base;
                &:nth-of-type(odd) {
                    background-color: $gray-lighter;
                }
                td {
                    font-size: $font-size-sm;
                }
                &.p-highlight {
                    // nå vi vælger en row i tabel, styler vi nogle af komponenterne her
                    background-color: $gray;
                }
            }
            .p-datatable-row {
                td {
                    .p-column-title {
                        text-transform: uppercase;
                    }
                }
                td:first-child {
                    .p-column-title {
                        @include media-breakpoint-down(sm) {
                            color: transparent;
                        }
                    }
                    .p-column-title::before {
                        content: "Select";
                        color: $black;
                    }
                }
            }
            // tr:nth-of-type(odd) {
            //     background-color: $table-accent-bg;
            // }
            // tr:hover {
            //     background-color: $table-hover-bg;
            // }
            tr.p-highlight {
                @include media-breakpoint-down(xs) {
                    // border-bottom: 1px solid $white;
                }

                td:first-child {
                    .p-column-title {
                        @include media-breakpoint-down(sm) {
                        }
                    }
                    .p-column-title::before {
                        color: $white;
                        // content: attr(data-source);
                    }
                }
            }

            tr.p-highlight:nth-child(even) {
                opacity: 0.95;
            }
            tr.p-highlight:hover {
                opacity: 0.9;
            }
        }
    }
    // }
    .p-datatable-tbody {
        .p-datatable-row {
            &:focus {
                outline: none;
            }
            td {
                border: 1px solid $gray-lighter;
            }
        }
    }
    .p-column-resizer-helper {
        background-color: $border-color;
    }
    // }

    &.p-datatable-gridlines {
        .p-datatable-table {
            // background-color: yellow;
            .p-datatable-thead {
                tr {
                    th {
                        border: 1px solid #dee2e6 !important;
                        border-width: 0px 0px 0px 1px !important;
                    }
                }
            }

            .p-datatable-tbody {
                // background-color: red;
                // > tr > td {
                //     border: 1px solid #dee2e6;
                //     background-color: greeN;
                // }
                tr {
                    td {
                        border: 1px solid #dee2e6;
                        border-width: 0px 0px 0px 1px;
                    }
                }
            }
        }
        // background-color: blue;
    }
}

.p-column-filter-overlay {
    //filter in datatable
    // vertical-align: top;
    // width: 100%;

    background-color: $form-select-bg;
    color: $form-select-color;
    border-color: $form-select-border-color;
    border-radius: 0;
    // box-shadow: $input-box-shadow;
    transition: $input-transition;
    border: $modal-content-border-color;
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
    .p-column-filter-operator {
        padding: 0.25rem;
        background-color: $gray-lighter;
    }
    .p-column-filter-constraints {
        padding: 0.25rem;
    }
    .p-column-filter-add-rule {
        padding: 0.25rem;
        .p-button {
            width: 100%;
            .p-button-icon {
                color: $black;
            }
        }
    }
    .p-column-filter-buttonbar {
        display: flex;
        justify-content: flex-start;
        padding-top: 1rem;
        .p-button {
            width: 100%;
        }
    }
}
//custom styles for header in attributelist table
.attribute-list-header {
    position: relative;
    .p-column-header-content {
        width: 100%;
        .p-column-title {
            // word-break: break-all;
            width: 100%;
            display: flex !important;
        }
    }
    &.p-align-right {
        // background-color: red;
    }
}

.attribute-table,
.activity-table {
    position: relative;
    .p-virtualscroller-content {
        right: 0;
    }
}

//custom styles for header in activity-table
.activity-list-header {
    position: relative;
    .p-column-header-content {
        width: 100%;
        .p-column-title {
            // word-break: break-all;
            // width: 100%;
            display: flex !important;
        }
    }
    &.p-align-right {
        // background-color: red;
    }
}

.activity-table {
    position: relative;
    .p-virtualscroller-content {
        right: 0;
    }
}
