.p-accordion {
    background: $white;
    border-top: 1px solid $border-color-light;
    .p-accordion-tab {
        .p-accordion-header {
            -webkit-transition: $transition;
            transition: $transition;

            margin-top: 0;
            font-family: $font-family-medium;
            background: transparent;
            padding: 0;
            border-bottom: 1px solid $border-color-light;
            .p-accordion-header-link {
                padding: 0.5rem 1rem;
                font-size: 1rem;
                color: $black;
                display: block;
                font-weight: 400;
                .p-accordion-toggle-icon {
                    color: $black;
                    float: right;
                    -webkit-transition: $transition-base;
                    transition: $transition-base;
                    padding-top: 3px;
                }

                .pi-caret-right:before {
                    font-family: $font-awesome;
                    content: "\f054";
                    font-weight: $font-awesome-weight;
                }
                .pi-caret-down:before {
                    font-family: $font-awesome;
                    content: "\f078";
                    font-weight: $font-awesome-weight;
                }
                .p-accordion-header-text {
                    color: $black;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                background-color: $gray-lighter;
            }
            &.p-highlight {
                background-color: $gray-lighter;

                .p-accordion-header-link {
                    .p-accordion-header-text,
                    .p-accordion-toggle-icon {
                        color: $dark;
                    }
                }
            }
        }

        .p-highlight {
        }
        .p-toggleable-content {
            ul {
                padding: 0;
                li {
                    list-style: none;
                    font-size: $font-size-base;
                    padding: 10px;
                    text-transform: uppercase;
                    font-family: $font-family-medium;
                    transition: $transition-base;
                    &:hover {
                        background-color: $gray-lighter;
                        cursor: pointer;
                    }
                    a {
                        color: $warning;
                    }
                }

                .menu_active {
                    background-color: $gray-lighter;
                }
            }
            .p-accordion-header-text {
                // background: red!important;
            }
            .p-accordion-content {
                // padding-top: 0;
                padding: 0.5rem 1rem;

                border-bottom: 1px solid $border-color-light;
                .p-accordion {
                    .p-accordion-tab {
                        .p-accordion-header {
                            border-bottom: 0;
                            a {
                                // padding: 0.15rem;
                            }
                        }
                    }
                    .p-accordion-tab:last-child {
                        // .p-accordion-header {
                        //     // &:last-child {
                        //     //      border-bottom: 1px solid $border-color;
                        //     // }
                        // }
                        .p-accordion-header {
                            border-bottom: 1px solid $border-color-light;
                        }
                        .p-highlight {
                            border-bottom: 0;
                        }
                        // .p-accordion-content {
                        //     border-bottom: 1px solid $border-color;
                        // }
                    }
                }
            }
        }
    }
    .p-accordion-tab:last-child {
        // .p-accordion-header {
        //     &:last-child {
        //         border-bottom: 1px solid $border-color;
        //     }
        // }
        // .p-accordion-header {
        //     // border-bottom: 1px solid $border-color;
        // }
        .p-highlight {
            border-bottom: 0;
        }
        // .p-accordion-content {
        //     // border-bottom: 1px solid $border-color;
        // }
    }
}
.accordion-list-detail {
    .p-accordion-tab {
        // .p-accordion-header {
        //     // border-bottom: 0 !important;
        // }
        .p-highlight {
            background: $white !important;
        }
        // .p-toggleable-content {
        //     // background: $white !important;
        //     // .p-accordion-header-text{
        //     //      background: red!important;
        //     //     text-transform: none;
        //     //     font-family: $font-family-regular;
        //     // }

        //     // .p-accordion-content {
        //     //     // border-bottom: 0 !important;
        //     // }
        // }
    }
}
