.p-error {
    display: block;
    color: $danger;
    transition: $transition-base;
}

.p-invalid {
    // background-color: lighten($danger, 30%);
    // font-family: "Font Awesome 5 Free";
    // content: "\f3be";
    // font-weight: 900;
    // background: red;
    // position: absolute;
    // height: 10px;
    // width: 10px;
    // z-index: 5555555;
    // left: 0;
    // right: 0;
}
