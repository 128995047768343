.p-calendar {
    position: relative;
    display: inline-flex;
    .p-inputtext {
        flex: 1 1 auto;
        width: 1%;
    }
    .p-button {
        padding: $input-padding-y-sm $input-padding-x-sm;
        // font-size: $custom-select-font-size-sm;
    }
}
.p-datepicker {
    min-width: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    border: $modal-content-border-color;
    box-shadow: $modal-content-box-shadow-xs;
    padding: 10px;

    .p-datepicker-group-container {
        .p-datepicker-group {
            /* Header */
            .p-datepicker-header {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .p-datepicker-title {
                    margin: 0 auto;
                    .p-datepicker-month {
                        margin-right: 3px;
                    }
                    .p-datepicker-month,
                    .p-datepicker-year {
                        font-size: 15px;
                        padding-right: 15px;
                        padding-left: 3px;

                        // background-color: $custom-select-bg;
                        // color: $custom-select-color;
                        // border: 1px solid $custom-select-border-color;
                        // border-radius: $custom-select-border-radius;
                        box-shadow: $input-box-shadow;
                        transition: $input-transition;
                        &:hover {
                            box-shadow: 0 0 0 $input-btn-focus-width rgba($form-select-border-color, 0.25);
                            border-color: darken($form-select-border-color, 10%);
                        }
                        &:focus {
                            outline: none;
                            border-color: darken($form-select-border-color, 10%);
                        }
                        &:active {
                            border-style: solid $btn-border-width $dark;
                            border-color: darken($form-select-border-color, 10%);
                        }
                    }
                }
                .p-datepicker-prev,
                .p-datepicker-next {
                    cursor: pointer;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    position: relative;
                    .p-datepicker-prev-icon::before,
                    .p-datepicker-next-icon::before {
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f054";
                    }
                    .p-datepicker-prev-icon::before {
                        content: "\f053";
                    }
                }
            }
            .p-datepicker-calendar {
                thead {
                    th {
                        span {
                            font-family: $font-family-regular;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            text-align: center;
                            padding: 0;

                            span,
                            a {
                                text-align: center;

                                width: 100%;
                                display: block;
                                cursor: pointer;
                            }
                            .p-disabled {
                                cursor: not-allowed;
                                color: $gray;
                            }
                            .p-highlight {
                                background-color: $blue-dark;
                                color: $white;
                            }
                            span:hover,
                            a:hover {
                                background-color: $blue-dark;
                                color: $white;
                            }
                        }

                        .p-datepicker-today {
                            color: $white;

                            background: $blue;
                        }
                    }
                }
            }
        }
    }
    .p-datepicker-buttonbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .p-button:first-child {
            left: 0px;
            background-color: $blue-dark;
            // color: $btn-primary-color;
            border-color: $blue-dark;
            border: $btn-border-width solid $blue-dark;
            &:hover {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($blue-dark, 0.25);
                background-color: darken($blue-dark, 10%);
                border-color: darken($blue-dark, 10%);
            }
            &:focus {
                outline: none;
                background-color: darken($blue-dark, 10%);
                border-color: darken($blue-dark, 10%);
            }
            &:active {
                border-style: solid $btn-border-width $blue-dark;
                background-color: darken($blue-dark, 10%);
                border-color: darken($blue-dark, 10%);
            }
        }
        .p-button:last-child {
            span::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f2ed";
                margin-right: 5px;
            }
            // background-color: $btn-danger-bg;
            // color: $btn-danger-color;
            // border-color: $btn-danger-border;
            // border: $btn-border-width solid $btn-danger-border;
            &:hover {
                // box-shadow: 0 0 0 $input-btn-focus-width rgba($btn-danger-border, 0.25);
                // background-color: darken($btn-danger-bg, 10%);
                // border-color: darken($btn-danger-border, 10%);
            }
            &:focus {
                outline: none;
                // background-color: darken($btn-danger-bg, 10%);
                // border-color: darken($btn-danger-border, 10%);
            }
            &:active {
                // border-style: solid $btn-border-width $btn-danger-border;
                // background-color: darken($btn-danger-bg, 10%);
                // border-color: darken($btn-danger-border, 10%);
            }
        }
    }
}
/* Fluid */
.p-fluid .p-calendar {
    display: flex;
}

.p-fluid .p-calendar .p-inputtext {
    // width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
}

.p-datepicker-inline {
    display: inline-flex;
    flex-direction: column;
    position: static;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
    display: flex;
}

/* DatePicker Table */
.p-datepicker {
    table {
        width: 100%;
        border-collapse: collapse;
    }
}

.p-datepicker td > span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

/* Month Picker */
.p-monthpicker-month {
    width: 33.3%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

/* Time Picker */
.p-timepicker {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
}

.p-timepicker > div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 80vw;
    transform: translate(-50%, -50%);
}
