.p-toast {
    box-shadow: $box-shadow;
    .p-toast-message {
        margin-bottom: 1px;
        color: $white;
        background-color: $black;
        padding: 0.75rem 1rem;
        .p-toast-message-content {
            align-items: center;

            .p-toast-message-icon {
                font-size: 1rem;
                &:before {
                    font-family: $font-awesome;
                    content: "\f12a";
                    font-weight: $font-awesome-weight;
                }
            }
            .p-toast-message-text {
                padding: 0 0 0 0.75rem;
                .p-toast-summary {
                    font-family: $font-family-heavy;
                    padding-right: 5px;
                }
                .p-toast-detail {
                    display: inline-block;
                }
            }
            .p-toast-icon-close {
                .p-toast-icon-close-icon {
                    color: $white;
                }
            }
        }
    }
    .p-toast-message-warn {
        background-color: $warning;
        .p-toast-message-content {
            .p-toast-message-icon {
                &:before {
                    // content: "\f00c";
                }
            }
            .p-toast-icon-close {
                .p-toast-icon-close-icon {
                    // color: $warning;
                }
            }
        }
    }
    .p-toast-message-success {
        background-color: $success;
        .p-toast-message-content {
            .p-toast-message-icon {
                &:before {
                    content: "\f00c";
                }
            }
        }
    }
    .p-toast-message-error {
        background-color: $danger;
        .p-toast-message-content {
            .p-toast-message-icon {
                &:before {
                    content: "\f071";
                }
            }
            .p-toast-icon-close {
                .p-toast-icon-close-icon {
                    // color: $danger;
                }
            }
        }
    }
}
//Custom made for confirmationview
.p-toast-confirm-dialog {
    .p-toast-message {
        padding: 0;
        .p-toast-message-content {
            display: block;
            .p-toast-confirm-header {
                padding: 0.25rem;
                .p-toast-confirm-title {
                    color: #fff;
                    margin: 0;
                    // background: $black;
                    color: $white;
                }
            }
            .p-toast-confirm-body {
                padding: 0.25rem;
                background-color: $white;
                color: $black;
            }

            .p-toast-confirm-footer {
                display: flex;
                justify-content: space-between;

                button {
                    width: 100%;
                }
            }
            .p-toast-icon-close {
                position: absolute;
                top: 6px;
                right: 6px;
            }
        }
    }
}
.p-toast-top-right {
    // top: 10vh;
    // right: 10vh;
}

.p-toast-center {
}
