.p-badge {
    background: $primary;
    color: $badge-color;
    font-size: $badge-font-size;
    // font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    // line-height: 1.5rem;
    // border: 1px solid black;
    font-family: $font-family-medium;

    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: 1.05rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    &.p-badge-success {
        background: $success;
    }
    &.p-badge-secondary {
        background: $secondary;
        border: 1px solid $dark;
        color: $dark;
    }
    &.p-badge-info {
        background: $info;
    }
    &.p-badge-warning {
        background: $warning;
    }
    &.p-badge-danger {
        background: $danger;
    }
}
.p-button {
    .p-badge {
        margin-left: 0.25rem;
    }
}
