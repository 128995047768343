.p-listbox {
    width: 15rem;
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 6px;
}
p-component {
}
.p-listbox-list-wrapper {
    vertical-align: top;
    width: 100%;
    background-color: $form-select-bg;
    color: $form-select-color;
    border-color: $form-select-border-color;
    border-radius: $form-select-border-radius;
    box-shadow: $input-box-shadow;
    transition: $input-transition;
    .p-listbox-list {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 90%;
        .p-listbox-item {
            padding: $form-select-padding-y $form-select-padding-x;
            padding-right: 25px;
            left: auto;
            right: 0;
            color: $form-select-color;
            width: 100% !important;
            &:hover {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($form-select-border-color, 0.25);
                border-color: darken($form-select-border-color, 10%);
            }
            &:focus {
                outline: none;
                border-color: darken($form-select-border-color, 10%);
            }
            &:active {
                border-style: solid $btn-border-width $dark;
                border-color: darken($form-select-border-color, 10%);
            }
            &.p-focus {
                border-color: darken($blue-dark, 10%);
                .p-dropdown-label {
                    border-color: darken($blue-dark, 10%);
                    box-shadow: inset 0 0 0 0.0625rem $blue-dark;
                }
            }
        }
    }
}
//Dialog style list - create  striped rows
.list-item-styleDialog {
    border-bottom: 1px solid $gray-light;
}
.list-item-styleDialog:nth-child(odd) {
    background-color: $gray-lighter;
}
