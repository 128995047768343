//@import "../../../../style/sass/abstracts/_required.scss";
/* @import "../../../../style/sass/abstracts/_required.scss";
@import "../../../../style/sass/abstracts/_variables.scss"; */
/* ==========================================================================
  Maps
   ========================================================================== */
/**
 * Table of Contents:
 *
 *  1. map
 *  2. 
 *  3. 
 *  4. 
 *  5. 
 *  6. 
 *  7. 
 *  8. 
 *  9. 
 *  10.
 *  11.
 *  12.
 *  13.
 *  14.
 *  15.
 *  16.
 *  17.
 *  18.
 *  19.
 *  20.
 *  21.
 *  22.
 *  23.
 *  24.
 */
/* 1. maps setup
   ========================================================================== */
$map-height: calc(100vh - 60px) !important;
$map-height-mobile: calc(100vh - 60px) !important;
$data-height: 22vh;
$data-height-mobile: 40vh;

.map-wrapper {
    height: $map-height;

    @include media-breakpoint-down(md) {
        height: $map-height-mobile;
    }

    .ol-viewport {
        //height: $map-height !important;
        height: $map-height;
        @include media-breakpoint-down(md) {
            // height:$map-height-mobile!important;
        }
        canvas {
            height: $map-height !important;
            height: $map-height;
            @include media-breakpoint-down(md) {
                height: $map-height-mobile !important;
            }
        }
    }
}
.data-wrapper {
    border-top: 5px solid $warning;
    height: $data-height;
    position: relative;
    overflow: auto;
    height: $data-height;
    @include media-breakpoint-down(md) {
        height: $data-height-mobile;
    }

    .p-datatable {
        margin-top: 0.5rem !important;
        // height: $data-height;

        max-height: $data-height;
        @include media-breakpoint-down(md) {
            height: $data-height-mobile !important;
            max-height: none;
        }
        .p-datatable-scrollable-wrapper {
            // height: calc($data-height-mobile - 34px);
            // height:$data-height;
            @include media-breakpoint-down(md) {
                // display: table;
                //  height:$data-height-mobile!important;
            }
            .p-datatable-scrollable-view {
                @include media-breakpoint-down(md) {
                    // display: table;
                    //  height:$data-height-mobile!important;
                }
                .p-datatable-scrollable-body {
                    @include media-breakpoint-down(md) {
                        //   height:calc(40vh - 75px)!important;
                    }
                    @include media-breakpoint-down(xs) {
                        // height:calc(40vh - 34px)!important;
                    }
                    table {
                        @include media-breakpoint-down(md) {
                            table-layout: auto !important;
                        }
                    }
                }
            }
        }
    }
}
.map-tool-wrapper {
    position: absolute;
    right: 20px;
    top: 60px;
}
.map-tool-items {
    padding-top: 15px;
    list-style: none;
    position: absolute;
    right: 0;
    ul {
        list-style: none;
        box-shadow: $box-shadow;
        padding-left: 0;
        text-align: right;
        li {
            button {
                padding: 0.1rem 0.3rem;
            }
        }
    }
}

.layerchooser {
    z-index: 1;
    box-shadow: $box-shadow;

    .p-accordion-tab-active {
        min-width: 250px;
    }
    .p-accordion {
        .p-accordion-tab {
            .p-accordion-header {
                .p-accordion-header-link {
                    // padding-top: 0.25rem;
                    // padding-bottom: 0.25rem;
                    padding: 0.1rem 0.6rem;
                    font-size: 0.8rem;
                }
            }
            .p-toggleable-content {
                .p-accordion-content {
                    padding: 0;
                    .p-accordion {
                        .p-accordion-tab {
                            .p-accordion-header {
                            }
                            .p-toggleable-content {
                                .p-accordion-content {
                                    padding: 0.25rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .field-radiobutton {
        label {
            font-size: 0.8rem;
        }
    }
}
//placing the search field on map
.addresssearch {
    position: absolute;
    z-index: 1;
    top: 16px;
    text-align: center;

    margin: 0 auto;
    left: 0;
    right: 115px;
    max-width: 300px;
    .p-autocomplete {
        max-width: 300px !important;
        width: 100%;
        .p-autocomplete-input {
            width: 100%;
            font-size: 1rem;
        }
    }
}
