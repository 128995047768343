.p-menu {
    box-shadow: $box-shadow;
    .p-menu-list {
        padding: 0.25rem 0.5em 0.5em 0.5em;
        border: #ccccce 0.25px solid;
        border-radius: 2px;
        opacity: 1;
        font-family: "SwecoSans-regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            sans-serif;
        background-color: #fff;
        .p-submenu-header {
            background: $border-color;
            height: 1px;
            color: transparent;
            margin-top: 5px;
        }
        .p-menuitem {
            padding-top: 0.25rem;
            // padding-top: 0.em;
            .p-menuitem-link {
                .p-menuitem-icon {
                    color: black;
                    padding-right: 2px;
                }
            }
            .p-menuitem-text {
                color: black;
                padding: 0.25em;
            }
            .p-disabled {
                opacity: 60%;
            }
        }
    }
}
