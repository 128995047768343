.p-card {
    background-color: $white;
    // max-width: 260px;
    // width: 100%;
    position: relative;
    box-shadow: $box-shadow-sm;
    transition: $transition-base;

    figure {
        transition: $transition-base;
    }
    .p-card-header {
        height: 150px;
        overflow: hidden;
        position: relative;
    }
    .p-card-body {
        margin-bottom: 26px;
        // min-height: 150px;

        .p-card-title {
            padding: 1rem 1rem 0rem 1rem;
        }
        .p-card-content {
            padding: 0 1rem;
            min-height: 50px;
        }
        .p-card-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            a {
                width: 100%;
                font-family: $font-family-medium;
                font-size: $input-btn-font-size;

                i {
                    position: relative;
                    right: 10px;
                    transition: right ease 0.5s;
                    // transition-delay: 0s;
                }
                &:hover,
                &:focus {
                    i {
                        right: -5px;
                        transition-delay: 200ms;
                    }
                }
            }
        }
    }
    &:hover {
        box-shadow: $box-shadow;
        figure {
            transform: scale(1.04);
        }
    }
}

// .animation-arrow i {
//     position: relative;
//     right: 10px;
//     transition: right ease 0.5s;
// }
// .animation-arrow:hover i {
//     right: -5px;
// }
