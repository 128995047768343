// .p-tabview {
//     padding: 0;
//     .p-tabview-nav {
//         padding: 0 !important;
//         margin-bottom: 5px;

//         @include media-breakpoint-down(sm) {
//             border-bottom: 0;
//         }
//         li {
//             border-left: 1px solid $gray-light;
//         }
//         li:first-child {
//             // border-left: none;
//         }
//         li:nth-last-child(2) {
//             border-right: 1px solid $gray-light;
//         }
//         .p-unselectable-text {
//             background: $white;
//             transition: background-color 0.3s ease;
//             margin: 0;
//             // border-top: 1px solid transparent;

//             // border-bottom: 1px solid $gray-light;
//             border-bottom: 1px solid transparent !important;

//             top: 0px !important;
//             @include media-breakpoint-down(sm) {
//                 border-bottom: 0 !important;
//                 display: inline-block;
//                 width: 100%;
//                 padding-left: 15px;
//             }
//             .p-tabview-nav-link {
//                 padding: $input-padding-y $input-padding-x;
//                 // text-decoration: none;

//                 @include media-breakpoint-down(sm) {
//                     padding: 0;
//                 }
//                 i {
//                     color: $black;
//                 }
//                 .p-tabview-title {
//                     line-height: $line-height-base;
//                     transition: background-color 0.3s ease;
//                     padding-left: 5px;
//                     // line-height: 19px;
//                     font-family: $font-family-medium;
//                     color: $black;
//                     @include media-breakpoint-down(sm) {
//                     }

//                     // text-transform: uppercase;
//                     // font-family: $font-family-medium;
//                 }
//             }
//         }
//         .p-unselectable-text::before {
//             @include media-breakpoint-down(sm) {
//                 content: "-";
//                 position: absolute;
//                 left: 0;
//             }
//         }
//         .p-unselectable-text:hover {
//             transition: background-color 0.3s ease;
//             background-color: $gray-lighter;
//         }
//         .p-tabview-selected {
//             // border: 1px solid #d8d8d8;
//             // border-bottom: none !important;
//             background-color: $black;

//             @include media-breakpoint-down(sm) {
//                 // border: none;
//                 // border-bottom: 0;
//             }

//             a {
//                 span,
//                 span.p-tabview-title,
//                 i {
//                     color: $white !important;
//                 }
//             }
//         }
//         .p-tabview-selected:hover {
//             // border: 1px solid #d8d8d8;
//             // border-bottom: 1px solid transparent !important;
//             background-color: darken($black, 10%);
//             // background-color: $blue-dark;
//             @include media-breakpoint-down(sm) {
//                 // border: none;
//                 // border-bottom: 0;
//             }
//             a {
//                 span,
//                 span.p-tabview-title {
//                     color: $white !important;
//                     // color: $black-light !important;
//                 }
//             }
//         }
//         .p-disabled {
//             :hover {
//                 cursor: not-allowed;
//             }

//             a {
//                 span,
//                 span.p-tabview-title {
//                     color: $gray;
//                 }
//             }
//         }
//     }

//     .p-tabview-panels {
//         width: 100%;
//         // padding: 1rem;
//         .p-tabview-panel {
//         }
//     }
//     &.p-tabview-sm {
//         .p-tabview-nav {
//             .p-unselectable-text {
//                 a {
//                     padding: $input-padding-y-sm $input-padding-x-sm;
//                     // font-size: $custom-select-font-size-sm;
//                 }
//             }
//         }
//     }
// }

// @include media-breakpoint-up(sm) {
//     .tabs-left-side {
//         display: flex;
//         .p-tabview-nav {
//             border-bottom: 0 !important;
//             margin-right: 1.5rem;
//             float: left;

//             li {
//                 width: 100%;
//                 margin-bottom: 3px !important;
//                 border: none !important;
//             }
//             li.p-unselectable-text {
//                 border-bottom: 0 !important ;
//             }
//             .p-tabview-selected,
//             .p-tabview-selected:hover,
//             .p-tabview-selected:focus {
//                 background-color: $primary;
//                 border-bottom: none;
//                 a {
//                     span {
//                         :hover {
//                             cursor: pointer;
//                         }
//                         // color: $white !important;
//                     }
//                 }

//                 width: calc(100% + 1rem);
//             }
//         }
//         .p-tabview-panel {
//             height: 100%;
//         }
//     }
//     .tabs-right-side {
//         display: flex;
//         flex-direction: row-reverse !important;
//         .p-tabview-nav {
//             border-bottom: 0 !important;
//             margin-top: 0.5rem;
//             margin-left: 0.5rem;
//             float: right;

//             li {
//                 width: 100%;
//                 margin-bottom: 3px !important;
//             }
//             li.p-unselectable-text {
//                 border-bottom: 0 !important ;
//             }
//             .p-tabview-selected,
//             .p-tabview-selected:hover,
//             .p-tabview-selected:focus {
//                 border-bottom: 0px solid transparent !important;
//             }
//         }
//         .p-tabview-panel {
//             height: 100%;
//         }
//         // .p-tabview-selected {

//         // }
//     }
// }
.p-tabview {
    .p-tabview-nav-container {
        .p-tabview-nav-content {
            .p-tabview-nav {
                border-bottom: 1px solid #e2e0da;
                .p-unselectable-text {
                    .p-tabview-nav-link {
                        margin-bottom: -2px;
                        .p-tabview-title {
                            padding: 0.5rem 1rem;
                            font-family: $font-family-medium;
                            color: black;
                            border: 1px solid transparent;
                            padding: 0.5rem 1rem;
                            transition: $transition-base;
                            &:hover {
                                background-color: #e2e0da;
                            }
                        }
                    }
                }
                .p-tabview-selected {
                    .p-tabview-nav-link {
                        .p-tabview-title {
                            background-color: #fff;
                            border-color: #e2e0da #e2e0da transparent;
                        }
                    }
                }
                .p-disabled {
                    .p-tabview-nav-link {
                        .p-tabview-title {
                            color: #e2e0da;
                        }
                    }
                }
            }
        }
    }
}
