.file-upload {
    color: transparent;
    display: block;
    margin-top: 10px;
}
.file-upload::-webkit-file-upload-button {
    visibility: hidden;
}
.file-upload::before {
    content: "Vælg fil";
    display: inline-block;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    padding: $input-padding-y $input-padding-x;
    font-family: $font-family-medium;
    border-width: $btn-border-width;
    margin-right: 0;
    border-style: unset;
    text-transform: uppercase;
    box-shadow: $btn-box-shadow;
    transition: $transition-base;
    line-height: inherit;
    background-color: $success;
    color: $white;
    border-color: $success;
    border: $btn-border-width solid $success;
}
.file-upload:hover::before {
    transition: $transition-base;
    box-shadow: 0 0 0 $input-btn-focus-width rgba($success, 0.25);
    background-color: darken($success, 10%);
    border-color: darken($success, 10%);
}
.file-upload:active {
    outline: 0;
}
.file-upload:active::before {
    background-color: darken($success, 10%);
}
