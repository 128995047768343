.p-password {
    width: 100%;
}
.p-password-panel {
    background-color: $white;
    padding: 0.5rem;
    -webkit-box-shadow: $dropdown-box-shadow;
    box-shadow: $dropdown-box-shadow;
    background-color: $white;
    .p-password-meter {
        .p-password-strength {
            background-color: $gray;
        }
        .weak {
            background-color: $danger;
        }
        .medium {
            background-color: $warning;
        }
        .strong {
            background-color: $success;
        }
    }
    .p-password-info {
    }
}
