.p-inputtext {
    padding: $input-padding-y $input-padding-x;
    // background-color: $custom-select-bg;
    // color: $custom-select-color;
    // border-color: $custom-select-border-color;
    // border-width: $custom-select-border-width;
    // border-radius: $custom-select-border-radius;
    border-style: solid;
    // box-shadow: $input-box-shadow;
    border: 1px solid $border-color;
    transition: $transition-base;
    font-size: $input-font-size;

    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($input-btn-focus-color, 0.25);
        // box-shadow: inset 0 0 0 0.0625rem $blue-dark;
        border-color: darken($input-btn-focus-color, 10%);
        transition: $transition-base;
    }
    &:focus {
        outline: none;

        // Old styles
        // border-color: darken($input-btn-focus-color, 10%);
        //box-shadow: inset 0 0 0 0.0625rem $input-btn-focus-color;
        transition: $transition-base;
        // Sweco style focus
        border-color: $green;
        box-shadow: inset 0 0 0 0.125rem $green;
    }
    &:active {
        border-style: solid $btn-border-width $dark;
        border-color: darken($input-btn-focus-color, 10%);
        box-shadow: inset 0 0 0 0.0625rem $input-btn-focus-color;
        transition: $transition-base;
    }
    //this readonly maybe effect on other divs...
    &:read-only {
        background-color: $disabled-bg;
    }
    &.p-disabled,
    &.p-disbled:hover {
        color: $disabled-color;
        border-color: $input-disabled-border-color;
        box-shadow: none;
        background-color: $disabled-bg;
        cursor: not-allowed !important;
    }
    &.p-invalid {
        border-color: $danger;
        &:hover {
            box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, 0.25);
            // box-shadow: inset 0 0 0 0.0625rem $blue-dark;
            border-color: darken($danger, 10%);
        }
        &:focus {
            outline: none;
            border-color: darken($danger, 10%);
            box-shadow: inset 0 0 0 0.0625rem $danger;
        }
        &:active {
            border-style: solid $btn-border-width $danger;
            border-color: darken($danger, 10%);
            box-shadow: inset 0 0 0 0.0625rem $danger;
        }
    }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;

    background: url(../../graphics/icons/times-solid.svg) no-repeat 50% 50%;
    background-size: contain;

    cursor: pointer;
}

input.p-invalid {
}
input.p-inputtext-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    // height: $custom-select-height-sm;

    line-height: inherit;

    // border: 1px solid $table-border-color;
}
input.p-inputtext-lg {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-base;
    // height: $custom-select-height-lg;
    // line-height: $input-btn-line-height-lg;
}
input.p-inputtext-xl {
    padding: calc($input-padding-y-lg * 1.25) calc($input-padding-x-lg * 1.25);
    font-size: calc($font-size-base * 1.25);
}

.p-float-label {
    margin-top: 30px;
    .p-inputtext {
    }
    label {
        left: 0.5rem;
        transition-duration: 0.3s;
    }
}

.p-input-icon-left {
    i {
        left: 0.5rem;
        top: 14px;
        z-index: 1;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
    .p-inputtext {
        padding-left: 2rem;
    }
}
.p-input-icon-right {
    i {
        top: 14px;
        right: 0.5rem;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        z-index: 1;
    }
    .p-inputtext {
        padding-right: 2rem;
    }
}
