.ol-dragbox {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #85c232;
}

.ol-draw,
.ol-box {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #85c232;
}
