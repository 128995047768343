/* .p-dialog-mask {
    z-index: 1031 !important;
} */
.p-dialog-top-left {
}
.p-dialog {
    border: $modal-content-border-color;
    box-shadow: $box-shadow;
    .p-dialog-header {
        background-color: $gray-dark;
        padding: 0.25rem 0rem 0.25rem 1rem;
        .p-dialog-title {
            font-size: $font-size-base;
            font-family: $font-family-medium;
            color: $white;
        }
        .p-dialog-header-icons {
            font-size: 1rem;
            color: $white;
            .p-dialog-header-close-icon::before {
                font-family: $font-awesome;
                font-weight: $font-awesome-weight;
                font-size: 1.5rem;
                color: $white;
                padding-left: 0.5rem;
                padding-right: 0.5rem;

                content: "\f00d";
            }

            .p-dialog-header-maximize-icon::before {
                padding-left: 0.5rem;
                color: $white;
            }
            .p-dialog-header-minimize-icon::before {
                color: $white;
                padding-left: 0.5rem;
            }
            &:hover {
                .p-dialog-header-icon {
                    .p-dialog-header-close-icon::before {
                        color: $gray-light;
                        transition: $transition-base;
                    }
                }
            }
        }
    }
    .p-dialog-content {
        // margin-bottom: 24px;
        background-color: $modal-content-bg;
        form {
            // Fix for not using footer as at prop
            height: calc(100% - 24px);
            .content {
                // height: calc(100% - 24px);
                height: 100%;
                //must be same height as footer
                margin-bottom: 24px;
            }
        }
    }
    //should be .p-dialog-content
    //we use- custom fix
    .pp-dialog-content {
        padding: $modal-inner-padding 0;
    }
    .p-dialog-footer {
        display: flex;
        justify-content: flex-start;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        .p-button {
            width: 100%;
        }
    }

    .p-resizable-handle::before {
        font-family: $font-awesome;
        font-weight: $font-awesome-weight;
        font-size: 1rem;
        color: $body-color;
        padding-left: 0.5rem;
        transform: rotate(45deg);
        content: "\f337";
        position: absolute;
        right: 0px;
        bottom: 2px;
    }
}

//size of the dialog when it is loaded. Dont use maxwidth on risize
.p-dialog-sm {
    width: 450px;
}
.p-dialog-md {
    width: 600px;
}
.p-dialog-lg {
    width: 600px;
}
.p-dialog-xl {
    width: 800px;
}
