// VARS for sidebar width
$sidebar-width: 300px;
$sidebar-width-sm: 280px;
.p-sidebar {
    padding: 0;
    top: 10px;
    width: $sidebar-width;
    z-index: 900 !important;
    top: 43px;
    height: calc(100% - 50px);

    .p-sidebar-content {
        padding: 0.5rem;
        background: $white;
        overflow: auto;

        -webkit-box-shadow: $box-shadow;
        box-shadow: $box-shadow;
    }
    .btn-open-sidebar {
        // padding: 0.1rem 0.3rem;
        // position: absolute;
        // left: 0;
        // top: 43px;
        // z-index: 1000;
    }
    .p-sidebar-close {
        background: $primary;
        text-align: center;
        color: $black;
        line-height: 0;

        margin-top: 26px;
        padding: 0.3rem;
        position: absolute;
        right: -26px;
        // @include media-breakpoint-up(xl) {
        //     display: none;
        // }
        span {
            color: $black;
            min-width: 1rem;
            min-height: 1rem;
            font-size: 0.8rem;
            font-weight: 600;
            font-family: "Font Awesome 5 Free";
            line-height: 1rem;
        }
        span::before {
            content: "\f00d" !important;
        }
    }
    fieldset {
        // background: $white;
    }
}
.p-sidebar-left {
    height: calc(100% - 93px);
}
.p-sidebar-active {
    .p-sidebar-close {
        display: block;
    }
}
.btn-open-sidebar {
    top: 0px;
}
.btn-open-sidebar {
    // text-align: center;
    // width: 35px;
    // height: 35px;
    // padding-left: 0.5rem !important;
    // padding-right: 0.5rem !important;
}

//placing content when sidebar is open/closed
@include media-breakpoint-up(md) {
    .openSidebarContentPos {
        padding-left: $sidebar-width + 15px;
        width: 100%;
        max-width: 100%;
        transition: $transition-base;
    }
    .closedSidebarContentPos {
        transition: $transition-base;
    }
}
