.p-multiselect {
    vertical-align: top;
    width: 100%;
    background-color: #fff;
    color: #212529;
    border-color: #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .p-multiselect-label-container {
        padding: 0.375rem 0.75rem;
        padding-right: 25px;
        left: auto;
        right: 0;
        color: #212529;
        width: 100% !important;
    }
    .p-checkbox-box {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        cursor: pointer;
        font-weight: normal;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
    }
}
.p-multiselect-header {
    .p-checkbox {
        margin-left: 11.1px;
    }
}

.p-multiselect-panel {
    min-width: 398px;
    transform-origin: center top;
    top: 1182.92px;
    left: 752.5px;
    z-index: 2010 !important;
    -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 30%);
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
    background-color: #fff;
    .p-component {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        cursor: pointer;
        font-weight: normal;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
    }
}
