.p-panel {
    padding: 0;
    .p-panel-titlebar {
        padding: 0;

        .p-panel-title {
            // font-size: $h4-font-size;
            font-size: $font-size-base;

            text-transform: uppercase;
            font-family: $font-family-medium;
            .badge {
                // min-height: 19px;
                position: absolute;
            }
        }
        .p-panel-titlebar-icon {
            margin-top: 3px;
            text-decoration: none;
            .pi {
                // font-size: 1rem;
                font-weight: 600;
                font-family: "Font Awesome 5 Free";
                color: $gray-dark;
                display: flex;
                transition: $transition-base;
                float: right;
            }
            .pi-plus::before {
                content: "\f107" !important;
                transform: rotate(0deg);
                transition: $transition-base;
            }
            .pi-minus::before {
                content: "\f107" !important;

                transform: rotate(-180deg);
                transition: $transition-base;
            }
        }
    }
    .p-toggleable-content {
        margin: 0;
        margin-top: 0.5rem;
        border-top: 1px solid $border-color;
        .p-panel-content {
            padding: 0.5em 0 0 0;
        }
    }
}
