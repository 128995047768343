//remember to wrap checkbox in the div field-checkbox
.field-checkbox {
    label {
        font-size: 0.8rem;
    }
    .p-checkbox {
    }
}

.p-checkbox {
    border: 0;
    -webkit-transition: $transition-base;
    transition: $transition-base;

    .p-hidden-accessible {
        input {
        }
    }
    .p-checkbox-box {
        width: 1.125rem;
        height: 1.125rem;
        line-height: 1.125rem;
        border: 1px solid $border-color;
        border-radius: 3px;
        background-color: $white;
        transition: $transition-base;
        margin-right: 8px;
        // box-shadow: 0 0.125rem 0.25rem rgba($primary, 0.075);
        // height: 20px;
        &:hover {
            // background-color: lighten($white, 10%);
            // box-shadow: 0 0.125rem 0.25rem rgba($primary, 0.075);
            box-shadow: 0 0 0 0.125rem $primary;
        }
        .p-checkbox-icon {
            text-align: center;
            color: $white;
            font-size: 0.7rem;
        }
        &:active {
            background-color: $black;
            border: 1px solid $black;
        }
    }

    .p-focus {
        // background-color: $black;
        // border: 1px solid $black;
    }

    .p-highlight {
        background-color: $black;
        border: 1px solid $black;
        .p-checkbox-icon:before {
            font-weight: 600;
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            color: $white;
        }
    }
    &.p-checkbox-disabled {
        .p-checkbox-box {
            background-color: $gray;
            border: 1px solid $gray;
        }
        &:hover {
            cursor: $cursor-disabled;
        }
    }
}
.p-checkbox-label {
    font-family: $font-family-medium;
}
.p-checkbox-label-disabled {
    color: $gray;
    &:hover {
        cursor: $cursor-disabled;
    }
}
