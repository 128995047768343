/* .attribute-area-wrapper {
    height: calc(100vh - 89px) !important;
    overflow-x: hidden;
    overflow-y: auto;
} */

// .p-tabview-panels {
//     .p-tabview-panel {
//         .p-datatable {
//             .p-datatable-thead {
//                 .p-dropdown {
//                     // width: 100%;
//                     // min-width: auto;
//                 }
//             }
//         }
//     }
// }

// .p-tabview {
//     @include media-breakpoint-up(md) {
//         margin-top: 1%;
//     }
// }
// .p-tabview {
//     .p-tabview-nav {
//         border-bottom: 2px solid #d8d8d8;
//         padding: 0 !important;

//         font-size: small;

//         .p-unselectable-text {
//             border: none;
//             border-top: 2px solid #d8d8d8;
//         }

//         .p-unselectable-text:nth-last-child(-n + 2) {
//             border-right: 2px solid #d8d8d8;
//         }
//         .p-tabview-selected {
//             margin-left: 0.5% !important;
//             margin-right: 0.5% !important;
//         }
//     }
// }
// @media (max-width: 767.98px) {
//     .p-tabview .p-tabview-nav {
//         border-bottom: 0;
//     }
// }
// .p-tabview .p-tabview-nav .p-unselectable-text {
//     top: 0;
//     background: #fff;
//     transition: background-color 0.3s ease;
//     margin: 0;
// }
// @media (max-width: 767.98px) {
//     .p-tabview .p-tabview-nav .p-unselectable-text {
//         border-bottom: 0 !important;
//         display: inline-block;
//         width: 100%;
//         padding-left: 15px;
//     }
// }
// @media (max-width: 767.98px) {
//     .p-tabview .p-tabview-nav .p-unselectable-text a {
//         padding: 0;
//     }
// }
// .p-tabview .p-tabview-nav .p-unselectable-text a span.p-tabview-title {
//     color: #b586a4;
//     text-transform: uppercase;
//     font-family: "SwecoSans-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
//         sans-serif;
// }
// @media (max-width: 767.98px) {
//     .p-tabview .p-tabview-nav .p-unselectable-text::before {
//         content: "-";
//         position: absolute;
//         left: 0;
//     }
// }
// .p-tabview .p-tabview-nav .p-unselectable-text:hover {
//     background-color: #eee;
// }
// .p-tabview .p-tabview-nav .p-tabview-selected {
//     border: 1px solid #d8d8d8;
//     border-bottom: 1px solid transparent !important;
//     background-color: #7e8dab;
// }
// @media (max-width: 767.98px) {
//     .p-tabview .p-tabview-nav .p-tabview-selected {
//         border: none;
//         border-bottom: 0;
//     }
// }
// .p-tabview .p-tabview-nav .p-tabview-selected a span,
// .p-tabview .p-tabview-nav .p-tabview-selected a span.p-tabview-title {
//     color: #fff;
// }
// .p-tabview .p-tabview-nav .p-tabview-selected:hover {
//     border: 1px solid #d8d8d8;
//     border-bottom: 1px solid transparent !important;
//     background-color: #7e8dab;
// }
// @media (max-width: 767.98px) {
//     .p-tabview .p-tabview-nav .p-tabview-selected:hover {
//         border: none;
//         border-bottom: 0;
//     }
// }

// .p-tabview .p-tabview-nav .p-disabled :hover {
//     cursor: not-allowed;
// }
// .p-tabview .p-tabview-nav .p-disabled a span,
// .p-tabview .p-tabview-nav .p-disabled a span.p-tabview-title {
//     color: #a5a5a8;
// }
// .p-tabview .p-tabview-panels {
//     overflow: auto;
//     min-height: 30vh;
//     width: 100%;
// }
// .p-tabview .p-tabview-panels .p-tabview-panel {
//     padding: 0;
// }

// /* 22. Tabs-left-side
//      ========================================================================== */
// @media (min-width: 576px) {
//     .tabs-left-side {
//         display: flex;
//     }
//     .tabs-left-side .p-tabview-nav {
//         border-bottom: 0 !important;
//         margin-right: 1.5rem;
//         float: left;
//     }
//     .tabs-left-side .p-tabview-nav li {
//         width: 100%;
//         margin-bottom: 3px !important;
//         border: none !important;
//     }
//     .tabs-left-side .p-tabview-nav li.p-unselectable-text {
//         border-bottom: 0 !important;
//     }
//     .tabs-left-side .p-tabview-nav .p-tabview-selected,
//     .tabs-left-side .p-tabview-nav .p-tabview-selected:hover,
//     .tabs-left-side .p-tabview-nav .p-tabview-selected:focus {
//         background-color: #7e8dab;
//         border-bottom: 0px solid transparent !important;
//         width: calc(100% + 1rem);
//     }
//     .tabs-left-side .p-tabview-nav .p-tabview-selected a span :hover,
//     .tabs-left-side .p-tabview-nav .p-tabview-selected:hover a span :hover,
//     .tabs-left-side .p-tabview-nav .p-tabview-selected:focus a span :hover {
//         cursor: pointer;
//     }
//     .tabs-left-side .p-tabview-panel {
//         height: 100%;
//     }
//     .tabs-right-side {
//         display: flex;
//         flex-direction: row-reverse !important;
//     }
//     .tabs-right-side .p-tabview-nav {
//         border-bottom: 0 !important;
//         margin-top: 0.5rem;
//         margin-left: 0.5rem;
//         float: right;
//     }
//     .tabs-right-side .p-tabview-nav li {
//         width: 100%;
//         margin-bottom: 3px !important;
//     }
//     .tabs-right-side .p-tabview-nav li.p-unselectable-text {
//         border-bottom: 0 !important;
//     }
//     .tabs-right-side .p-tabview-nav .p-tabview-selected,
//     .tabs-right-side .p-tabview-nav .p-tabview-selected:hover,
//     .tabs-right-side .p-tabview-nav .p-tabview-selected:focus {
//         border-bottom: 0px solid transparent !important;
//     }
//     .tabs-right-side .p-tabview-panel {
//         height: 100%;
//     }
// }

/* 23 Messure-tool
=======================================================================================*/

.measure-tool {
    top: 175px;
    position: absolute;
    right: 15px;
    box-shadow: 0 0 $box-shadow-size $box-shadow;
    button {
        height: 35px;
        width: 35px;
        border: 1px solid $black;
        background-color: $black;

        &:hover {
            border: 1px solid $black;
            background-color: $black;
        }
        &:focus {
            border: 1px solid $black;
            background-color: $black;
        }
    }
    .btn-measure-active {
        background-color: $info;
        border: 1px solid $info;
        color: $white;
        &:hover {
            border: 1px solid $info;
            background-color: $info;
        }
        &:focus {
            border: 1px solid $info;
            background-color: $info;
        }
    }
}

.tooltip {
    // position: relative;
    // background: rgba(0, 0, 0, 0.5);
    // color: $white;
    // padding: 4px 8px;
    // white-space: nowrap;
}

.tooltip-static {
    background-color: $info;
    color: $white;
    box-shadow: 0 0 $box-shadow-size $box-shadow;
}

.tooltip-measure:before,
.tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}
.tooltip-measure:before {
    border-top: 6px solid $info;
}
.tooltip-measure {
    background-color: $info;
}
.tooltip-static:before {
    border-top-color: $info;
}
/* 24 Left nav
=======================================================================================*/
// .left-nav {
//     padding-top: 2px;
//     .btn-left-nav {
//         // color: $white;
//         // bottom: $data-height;
//         // line-height: 35px;
//         text-align: center;
//         -webkit-box-shadow: 0 0 $box-shadow-size $box-shadow;
//         box-shadow: 0 0 $box-shadow-size $box-shadow;
//         background: $dark-gray;
//         cursor: pointer;
//         z-index: 1012;
//         height: 38px;
//         width: 38px;
//         line-height: 38px;

//         .btn-no-style {
//             width: 100%;
//         }
//     }
// }

.p-datatable-scrollable-header {
    overflow: visible !important;
}
.p-datatable table .p-datatable-thead tr .p-sortable-column .p-inputtext {
    position: initial;
    // padding: 0.315rem 0.5rem;
}

.side-height-size-open {
    height: calc(70vh - 60px) !important;
    transition: $transition-base;
}
.side-height-size-closed {
    height: calc(100vh - 60px) !important;
    transition: $transition-base;
}

.btn-refresh-place {
    position: absolute;

    @media (min-width: 768px) {
        left: 5px;
        margin-top: 47px;
    }
    @media (max-width: 767px) {
        top: 8px;
        margin-left: 42px;
    }
}
.p-sidebar-active {
    .p-sidebar-icons {
        // display: block;
    }
    .tab-view-footer-place {
        .p-tabview-nav {
            @media (min-width: 768px) {
                // border-top: 2px solid $warning;
                // border-left: 2px solid $warning;
                // border-right: 2px solid $warning;

                // position: fixed;
                // top: -33px;
                // left: 45px;
            }
            @media (max-width: 767px) {
            }
        }
    }
}
.p-paginator {
    background-color: $white;
}

.history-subtable {
    margin-bottom: 15px;
    border: 1px solid $border-color-light;
    border-top: 0;
    .p-datatable {
        // background-color: $gray-light;
    }
}
.p-panel-custom {
    .p-panel-header {
        .p-panel-title {
            position: absolute;
        }
        .p-panel-icons {
            width: 100%;
            .p-panel-header-icon {
                width: 100%;
                text-align: right;
                display: block;
            }
        }
    }
}
