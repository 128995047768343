.p-colorpicker {
    text-align: center;
    border: 1px solid $black;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    display: flex;
    .p-colorpicker-preview {
        padding: 0;
        border: none;
        // font-size: 1rem;
        height: 18px;
        width: 18px;
        padding: 0;
        margin: 0;
    }
}
.p-colorpicker::before {
    // font-family: "Font Awesome 5 Free";
    // font-weight: 900;
    // content: "\f00d";

    // position: absolute;
    // width: 100%;
}
.p-colorpicker-panel {
    // vertical-align: top;
    background-color: $form-select-bg;
    color: $form-select-color;
    border-color: $form-select-border-color;
    // border-radius: $form-select-border-radius;
    box-shadow: $box-shadow;
    transition: $input-transition;
}
//Customstyle for open/close colorpicker icon
.colorpickerClose,
.colorpickerClose:focus,
.colorpickerClose:active {
    // padding: 0;
    // border: none;
    // font-size: 1rem;
}
