.p-inputgroup {
    margin-bottom: 0.5rem;
    .p-inputgroup-addon {
        padding: $input-padding-y $input-padding-x;
        font-family: $font-family-medium;
        // font-weight: $btn-font-weight;
        border-width: $btn-border-width;
        margin-right: 0;
        border-style: unset;
        text-transform: uppercase;
        box-shadow: $btn-box-shadow;
        transition: $transition-base;
        line-height: inherit;
        background-color: $gray;
        color: $white;
        // border-color: $gray;
        border: $btn-border-width solid $gray;
        min-width: 45px;
        i {
        }
        .p-inputtext {
        }
    }
}
