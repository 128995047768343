.nav-item,
.nav-link {
    // font-family: $headings-font-family;
    font-weight: normal !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-transform: uppercase;
    padding-right: 1.5rem !important;
    position: relative;
    border: 0;
    background-color: transparent;
    color: #fff;
    white-space: nowrap;
    // padding: 7px 0 2px !important;
    // height: 32px;
    // min-height: 32px !important;
    margin-left: 1rem;
}

.navbar-brand {
    font-family: $headings-font-family;
    text-transform: uppercase;
}
.drp-d {
    // padding-bottom: 6px;
}
.or-img {
    // vertical-align: baseline;
}
.fot-pad {
    padding-top: 1rem;
    padding-bottom: 0.6rem;
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
// Overrules normal dropdown in header
.nav-dropdown {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        border: none;
        .p-dropdown-label {
            box-shadow: none;
            border: none;
        }
    }
    .p-dropdown-label {
        padding: 0 0.75rem;
        color: white;
        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
            border: none;
        }
    }
    .p-inputtext {
        border: none;
        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
            border: none;
        }
    }
    .p-dropdown-trigger {
        color: white;
        padding-left: 5px;
    }
    .p-focus {
        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
            border: none;
        }
        .p-dropdown-label {
            box-shadow: none;
            border: none;
            &:hover,
            &:focus,
            &:active {
                box-shadow: none;
                border: none;
            }
        }
    }
}
