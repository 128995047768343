.p-dropdown {
    vertical-align: top;
    width: 100%;
    background-color: $form-select-bg;
    color: $form-select-color;
    border-color: $form-select-border-color;
    border-radius: $form-select-border-radius;
    box-shadow: $input-box-shadow;
    transition: $input-transition;
    border-radius: 0;
    .p-dropdown-label {
        padding: $form-select-padding-y $form-select-padding-x;
        padding-right: 25px;
        left: auto;
        right: 0;
        color: $form-select-color;
        width: 100% !important;
        &:read-only {
            //overule the real readonly
            background-color: transparent;
        }
        &:focus {
            // border-color: darken($form-select-border-color, 10%);
            // Sweco style focus
            border-color: $green;
        }
    }
    .p-dropdown-label-empty {
        visibility: visible;
        color: white; //Hides the "empty" label text in the dropdown.
    }
    &:hover {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($form-select-border-color, 0.25);
        border-color: darken($form-select-border-color, 10%);
    }
    &:focus {
        outline: none;
        // border-color: darken($form-select-border-color, 10%);
        // Sweco style focus
        border-color: $green;
        box-shadow: inset 0 0 0 0.125rem $green;
    }
    &:active {
        border-style: solid $btn-border-width $dark;
        border-color: darken($form-select-border-color, 10%);
    }
    &.p-focus {
        // border-color: darken($input-btn-focus-color, 10%);
        // Sweco style focus
        border-color: $green;
        box-shadow: inset 0 0 0 0.125rem $green;
        .p-dropdown-label {
            border-color: darken($input-btn-focus-color, 10%);
            box-shadow: inset 0 0 0 0.0625rem $input-btn-focus-color;
        }
    }
    &.p-disabled,
    &.p-disbled:hover {
        border-color: $disabled-bg;
        box-shadow: none;
        .p-dropdown-label {
            background-color: $disabled-bg;
            cursor: not-allowed !important;
            box-shadow: none;
        }
    }
    .p-dropdown-trigger {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        .p-dropdown-trigger-icon::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\F107";
        }
    }
    &.p-dropdown-clearable {
        .p-dropdown-clear-icon {
            position: absolute;
            right: 25px;
            font-size: 15px;
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\F00d";
            }
        }
    }
}
.p-dropdown-panel {
    // z-index: 20000 !important;
    -webkit-box-shadow: $dropdown-box-shadow;
    box-shadow: $dropdown-box-shadow;
    background-color: $white;
    .p-dropdown-header {
        .p-dropdown-filter-container {
            .p-dropdown-filter-icon {
                position: absolute;
                right: 5px;
                &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\F002";
                }
            }
        }
    }
    .p-dropdown-items-wrapper {
        .p-dropdown-items {
            // padding: 0 0 0 5px;

            -webkit-transition: $transition-base;
            transition: $transition-base;
            margin: 0;
            .p-dropdown-item {
                padding-right: $form-select-padding-x;
                padding-left: $form-select-padding-x;
                // padding-right: $form-select-padding-x;
                // padding-left: $form-select-padding-x;
                // -webkit-transition: $transition-base;
                // transition: $transition-base;
                font-size: $input-font-size;
                // margin: 0;
            }
            .p-dropdown-item:hover {
                background-color: $black;
                color: $white;
            }
            .p-dropdown-empty-message {
                padding-right: $form-select-padding-x;
                padding-left: $form-select-padding-x;
            }
        }
    }
    .p-dropdown-filter-container {
        width: 100%;
        .p-dropdown-filter {
            padding: $input-padding-y-sm $input-padding-x-sm;
            font-size: $form-select-font-size-sm;
        }
    }
}

.p-dropdown-sm {
    & .p-dropdown-label {
        padding: $input-padding-y $input-padding-x-sm;
        padding-right: 25px;

        font-size: $form-select-font-size-sm;
    }
}
.p-dropdown-lg {
    & .p-dropdown-label {
        padding: $form-select-padding-y $form-select-padding-x;
        padding-right: 25px;
        font-size: $form-select-font-size-lg;
        // height: $custom-select-height-lg;
        // line-height: $input-btn-line-height-lg;
    }
}

.p-inputtext {
    border: 1px solid $border-color;
}
