.p-tooltip {
    .p-tooltip-text {
        box-shadow: $box-shadow;
        background-color: $tooltip-bg;
        color: $white;
        padding: $tooltip-padding-y $tooltip-padding-x;
        font-size: $font-size-sm;
        // z-index: $zindex-tooltip;
        max-width: $tooltip-max-width;
        opacity: $tooltip-opacity;
    }

    .p-tooltip-arrow {
        opacity: $tooltip-opacity;
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            top: 50%;
            border-right-color: $tooltip-bg;

            left: 0;
            margin-top: -0.25em;
            border-width: 0.25em 0.25em 0.25em 0;
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            top: 50%;
            border-left-color: $tooltip-bg;
            right: 0;
            margin-top: -0.25em;
            border-width: 0.25em 0 0.25em 0.25em;
        }
    }

    &.p-tooltip.p-tooltip-top {
        padding: 0.25em 0;
        border-top-color: $tooltip-bg;
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            bottom: 0;
            border-top-color: $tooltip-bg;
            left: 50%;
            margin-left: -0.25em;
            border-width: 0.25em 0.25em 0;
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            top: 0;
            left: 50%;
            border-bottom-color: $tooltip-bg;
            margin-left: -0.25em;
            border-width: 0 0.25em 0.25em;
        }
    }
}
