$paginator-transisiton-speed: 0.2s;
.p-paginator {
    padding: 5px 0;

    .p-paginator-element {
        height: 25px;
        text-align: center;
        font-size: 0.75rem;
        min-width: 1rem;
        .p-paginator-icon {
            font-size: 12px;
            line-height: 25px;
            font-family: $font-awesome;
            font-weight: $font-awesome-weight;
        }
        &.p-paginator-first {
            .p-paginator-icon::before {
                content: "\F048";
            }
        }
        &.p-paginator-prev {
            .p-paginator-icon:before {
                content: "\F053";
            }
        }
        &.p-paginator-next {
            .p-paginator-icon:before {
                content: "\F054";
            }
        }
        &.p-paginator-last {
            padding-right: 5px;

            .p-paginator-icon:before {
                content: "\F051";
            }
        }
    }
    .p-paginator-element::after {
        content: "";
        display: block;
        height: 2px;
        background: $green;
        transition: ease all $paginator-transisiton-speed;
        position: absolute;
        width: 100%;
        bottom: 8px;
        opacity: 0;
    }
    .p-paginator-element:hover::after {
        bottom: 0px;
        height: 2px;
        opacity: 1;
        z-index: 1000;
    }
    .p-highlight {
        color: $green;
        font-weight: bold;
    }
    .p-highlight::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        opacity: 1;
        width: 100%;
        height: 2px;
        background: $green;
    }
    .p-paginator-current {
        padding-right: 0.25rem;
        line-height: $line-height-base;
        font-size: 0.75rem;
    }

    .p-dropdown {
        border: none;
        padding: 0;
        box-shadow: none;
        width: auto;
        vertical-align: middle;

        &.p-focus:hover::after {
            background-color: transparent;
            height: 0;
        }
        .p-highlight::after {
            background-color: transparent;
            height: 0;
        }
        .p-dropdown-label {
            box-shadow: none;
            padding: $input-padding-y-sm $input-padding-x-sm;
            padding-right: 25px;
            font-size: 0.75rem;

            // font-size: $custom-select-font-size-sm;
        }
        .p-dropdown-panel {
            .p-dropdown-items-wrapper {
                ul {
                    padding: 0;
                    li {
                        padding: 0.125em 0.75em;
                        text-align: center;
                    }
                }
            }
        }
        &.p-dropdown-sm {
        }
    }
    .p-dropdown::after {
        content: "";
        display: block;
        height: 2px;
        background: $black;
        transition: ease all $paginator-transisiton-speed;
        position: absolute;
        width: 100%;
        bottom: 3px;
        opacity: 0;
    }
    .p-dropdown:hover::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        opacity: 1;
        width: 100%;
        height: 2px;
        background: $black;
    }

    .p-paginator-pages {
        padding: 0 0px;

        .p-paginator-element {
            color: $black;
            min-width: 1.5rem;
            font-family: $font-family-medium;
        }
        .p-highlight {
            color: $black;
            font-family: $font-family-heavy;
        }
    }
    .p-disabled {
        color: $gray;
        &:hover {
            cursor: not-allowed !important;
        }
    }
    .p-disabled::after {
        background-color: transparent !important;
    }
}
