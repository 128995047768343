.p-speeddial {
    transition: $transition-base;
    position: relative;
    .p-button {
        width: 100%;
        // width: 32px;
        // height: 32px;
    }
    .p-speeddial-list {
        .p-speeddial-item {
            padding: 0rem;
            height: 0px;
            .p-speeddial-action {
                height: 0;
                color: $black;
                .p-speeddial-action-icon {
                    height: 0;
                }
            }
        }
    }
    .p-disabled {
        opacity: 60%;
    }
}
.p-speeddial-direction-left {
    .p-speeddial-list {
        padding-right: 10px;
    }
}
.p-speeddial-opened {
    background-color: $gray-lighter;
    box-shadow: $box-shadow;
    .p-button {
        box-shadow: none;
    }
    .p-speeddial-list {
        .p-speeddial-item {
            height: auto;
            padding: 0.5rem;
            .p-speeddial-action {
                height: auto;
                .p-speeddial-action-icon {
                    height: auto;
                }
            }
        }
    }
    .p-speeddial-rotate {
        transform: rotate(90deg);
    }
}

// Style to make the scenario toolbox/speeddial work- Normaly it is acting like crazy
.speeddial-scenario {
    background-color: blue;
    .p-button {
    }
    .p-speeddial-list {
        .p-speeddial-item {
            padding: 0 !important;
            .p-speeddial-action {
                width: 0;
                .p-speeddial-action-icon {
                    width: 0;
                }
            }
        }
    }
}

.speeddial-scenario.p-speeddial-opened {
    background-color: red;
    .p-button {
    }
    .p-speeddial-list {
        .p-speeddial-item {
            padding: 10px;
            width: auto;
            .p-speeddial-action {
                width: auto;
                .p-speeddial-action-icon {
                    width: auto;
                }
            }
        }
    }
}
.speeddial-linear-demo .p-speeddial-direction-up {
    left: calc(50% - 2rem);
    bottom: 0;
}
.speeddial-linear-demo .p-speeddial-direction-down {
    left: calc(50% - 2rem);
    top: 0;
}
.speeddial-linear-demo .p-speeddial-direction-left {
    right: 0;
    top: calc(50% - 2rem);
}
.speeddial-linear-demo .p-speeddial-direction-right {
    left: 0;
    top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-circle {
    top: calc(50% - 2rem);
    left: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-up {
    left: calc(50% - 2rem);
    bottom: 0;
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-down {
    left: calc(50% - 2rem);
    top: 0;
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-left {
    right: 0;
    top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-semi-circle.p-speeddial-direction-right {
    left: 0;
    top: calc(50% - 2rem);
}
.speeddial-circle-demo .p-speeddial-quarter-circle.p-speeddial-direction-up-left {
    right: 0;
    bottom: 0;
}
.speeddial-circle-demo .p-speeddial-quarter-circle.p-speeddial-direction-up-right {
    left: 0;
    bottom: 0;
}
.speeddial-circle-demo .p-speeddial-quarter-circle.p-speeddial-direction-down-left {
    right: 0;
    top: 0;
}
.speeddial-circle-demo .p-speeddial-quarter-circle.p-speeddial-direction-down-right {
    left: 0;
    top: 0;
}
.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-left {
    left: 0;
    bottom: 0;
}
.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-right {
    right: 0;
    bottom: 0;
}
.speeddial-delay-demo .p-speeddial-direction-up {
    left: calc(50% - 2rem);
    bottom: 0;
}
.speeddial-mask-demo .p-speeddial-direction-up {
    right: 0;
    bottom: 0;
}
